export const env = {
  "SUDO_GID": "0",
  "USER": "buildbot",
  "npm_config_user_agent": "npm/9.6.6 node/v20.2.0 linux x64 workspaces/false",
  "CI": "true",
  "CF_PAGES_COMMIT_SHA": "cfe41dfbb43cef2c2ce8693336b6fbdfdb6139b1",
  "ROOT_DIR": "/",
  "npm_node_execpath": "/opt/buildhome/.asdf/installs/nodejs/20.2.0/bin/node",
  "SHLVL": "1",
  "npm_config_noproxy": "",
  "HOME": "/opt/buildhome",
  "npm_package_json": "/opt/buildhome/repo/package.json",
  "NODE_OPTIONS": "--max_old_space_size=4096",
  "npm_config_userconfig": "/opt/buildhome/.npmrc",
  "npm_config_local_prefix": "/opt/buildhome/repo",
  "PKG_EXECPATH": "/opt/build/bin/build",
  "COLOR": "0",
  "CF_PAGES_BRANCH": "develop",
  "npm_config_metrics_registry": "https://registry.npmjs.org/",
  "SUDO_UID": "0",
  "LOGNAME": "buildbot",
  "npm_config_prefix": "/opt/buildhome/.asdf/installs/nodejs/20.2.0",
  "npm_config_npm_version": "9.9.3",
  "TERM": "unknown",
  "npm_config_cache": "/opt/buildhome/.npm",
  "npm_config_node_gyp": "/opt/buildhome/repo/node_modules/npm/node_modules/node-gyp/bin/node-gyp.js",
  "PATH": "/opt/buildhome/repo/node_modules/.bin:/opt/buildhome/node_modules/.bin:/opt/node_modules/.bin:/node_modules/.bin:/opt/buildhome/repo/node_modules/npm/node_modules/@npmcli/run-script/lib/node-gyp-bin:/opt/buildhome/repo/node_modules/.bin:/opt/buildhome/node_modules/.bin:/opt/node_modules/.bin:/node_modules/.bin:/opt/buildhome/repo/node_modules/npm/node_modules/@npmcli/run-script/lib/node-gyp-bin:/opt/buildhome/repo/node_modules/.bin:/opt/buildhome/node_modules/.bin:/opt/node_modules/.bin:/node_modules/.bin:/opt/buildhome/.asdf/installs/nodejs/20.2.0/lib/node_modules/npm/node_modules/@npmcli/run-script/lib/node-gyp-bin:/opt/buildhome/.asdf/plugins/nodejs/shims:/opt/buildhome/.asdf/installs/nodejs/20.2.0/bin:/opt/buildhome/.asdf/shims:/opt/buildhome/.asdf/bin:/opt/buildhome/.swiftenv/bin:/opt/buildhome/.swiftenv/shims:/opt/buildhome/.php:/opt/buildhome/.binrc/bin:/usr/local/rvm/bin:/usr/local/sbin:/usr/local/bin:/usr/sbin:/usr/bin:/sbin:/bin:/opt/buildhome/.cask/bin:/opt/buildhome/.gimme/bin:/opt/buildhome/.dotnet/tools:/opt/buildhome/.dotnet:/opt/buildhome/.local/bin",
  "CF_PAGES_URL": "https://68ce2e1a.angelpath-admin.pages.dev",
  "NODE": "/opt/buildhome/.asdf/installs/nodejs/20.2.0/bin/node",
  "npm_package_name": "thkee-app",
  "npm_lifecycle_script": "node -e \"const fs = require('fs'); const env = { ...process.env, npm_package_version: require('./package.json').version }; fs.writeFileSync('./projects/thkee-admin-app/src/environments/.env.ts', 'export const env = ' + JSON.stringify(env, null, 2) + ';')\"",
  "npm_config_loglevel": "silent",
  "SUDO_COMMAND": "/opt/build/bin/build npm run build",
  "SHELL": "/bin/bash",
  "npm_package_version": "0.9.1",
  "npm_lifecycle_event": "env-admin",
  "REPO_DIR": "/opt/buildhome/repo",
  "SUDO_USER": "root",
  "npm_config_globalconfig": "/opt/buildhome/.asdf/installs/nodejs/20.2.0/etc/npmrc",
  "npm_config_init_module": "/opt/buildhome/.npm-init.js",
  "PWD": "/opt/buildhome/repo",
  "npm_execpath": "/opt/buildhome/repo/node_modules/npm/bin/npm-cli.js",
  "CF_PAGES": "1",
  "npm_config_global_prefix": "/opt/buildhome/.asdf/installs/nodejs/20.2.0",
  "npm_command": "run-script",
  "BUILD_ENV": "dev-admin",
  "INIT_CWD": "/opt/buildhome/repo",
  "EDITOR": "vi"
};