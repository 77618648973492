<div class="course-overview p-4 pt-0">
   <div class="flex flex-col gap-4 mb-4">
      <!-- <div *ngIf="!requestsSummary" class="flex justify-center py-6" [thkLibProcessing]="true" ></div> -->
      <ng-container *ngIf="requestsSummary">
         <div class="flex flex-row gap-4">
            <app-metric-card class="w-full" [data]="{ title: 'Total Sales', value: (requestsSummary.total_sales | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Paypal', value: (requestsSummary.total_payee | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Payoneer Balances', value: (requestsSummary.payoneer_balance | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Bank Balances', value: (requestsSummary.bank_balance | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Total To Pay', value: (requestsSummary.total_amount_to_pay | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
         </div>
         <div class="flex flex-row gap-4">
            <app-metric-card class="w-full" [data]="{ title: 'Total Transactions', value: requestsSummary.total_transactions + '' }">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Total of Payees', value: requestsSummary.total_payee + '' }">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Bank Payment', value: (requestsSummary.bank_payment | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Paypal Payment', value: (requestsSummary.paypal_payment | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
            <app-metric-card class="w-full" [data]="{ title: 'Payoneer Payment', value: (requestsSummary.paypal_payment | currency:requestsSummary.currency_symbol) || ''}">
            </app-metric-card>
         </div>
      </ng-container>
   </div>
   <!-- Warnings -->
   <div class="px-6 py-4 mb-4 bg-rose-50 flex gap-2.5 rounded-md">
      <svg-icon src="@assets/images/icons/exclamation-circle.svg" class="w-[26px] h-[26px] text-rose-500">
      </svg-icon>
      <div class="flex flex-col gap-2.5 flex-1">
         <h3 class="text-rose-500 text-xl font-semibold">Attention</h3>
         <p class="text-rose-500 text-base font-normal mb-0">All Monthly Type Payouts Request have been created on 1st
            June 2023 For April 2023 Period Please Approve it and make the payout for instructors as soon as possible to
            prevent any delay. </p>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="toggleDropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>Filter
            </button>
            <div [ngClass]="showingDropdown === 'filter' ? '' : 'hidden'"
               class="bg-white shadow-lg rounded px-4 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <formly-form #formlyFilter [form]="filterForm" [fields]="filterFormFields">
                  </formly-form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilterRequests()" class="btn btn-outline-dark border-neutral-600">Reset</button>
               </div>
            </div>
         </div>
         <app-combination-single-input [keys]="searchOptions" (valueChanges)="search($event)" [model]="searchValue"></app-combination-single-input>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6> Payouts Requests</h6>
               <div class="tag tag-sm">{{requestsPagination?.count}} Payee</div>
            </div>

            <div class="actions flex flex-row gap-4">
               <button *ngIf="!disableManyAction && selectedRequestIds.length" class="btn btn-teal" (click)="requestConfirmation(approveAllModal, selectedRequestIds)">Approve ({{selectedRequestIds.length}})</button>
               <button *ngIf="!disableManyAction && selectedRequestIds.length" class="btn btn-danger" (click)="requestConfirmation(deactivateAllModal, selectedRequestIds)">Deactivate ({{selectedRequestIds.length}})</button>
               <div class="select2">
                  <div class="relative">
                     <button class="btn btn-white !pr-2" (click)="toggleDropdown('batchOption')">
                        Batch Option
                        <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                     </button>
                     <ul [ngClass]="showingDropdown === 'batchOption' ? '' : 'hidden'" tabindex="-1" role="listbox" class="select-items"
                        ng-reflect-ng-class="hidden">
                        <li (click)="requestConfirmation(approveAllModal)" id="listbox-option-0" role="option" class="item">Approve All</li>
                        <li (click)="requestConfirmation(deactivateAllModal)" id="listbox-option-0" role="option" class="item">Deactivate All</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <ng-template #listPayoutRequestAllPagination let-items>
            <div class="card-body">
               <div class="courses-list">
                  <!-- Table -->
                  <div class="datagrid-table relative overflow-x-auto">
                     <table thkNestedCheckbox (selectedChange)="handleSelectedChange($event)" class="w-full text-left" #payoutCheckboxes>
                        <thead class="heading">
                           <tr>
                              <th scope="col" class="w-28 pl-4">
                                 <div class="col-name flex items-center gap-2">
                                    <input thkNestedCheckboxChild [leader]="true" type="checkbox">
                                    {{selectedRequestIds.length}} Selected
                                 </div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Instructor</div>
                              </th>
                              <th scope="col" class="w-28">
                                 <div class="col-name">Transactions</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Payout Method</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Payout Type</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Created on</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Total Amount</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Status</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Actions</div>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngIf="requestsPagination?.count === 0">
                              <td class="text-center" colspan="999">No payouts found</td>
                           </tr>
                           <tr *ngFor="let data of requestsPagination?.results; let i = index" class="hover:cursor-pointer">
                              <td>
                                 <input thkNestedCheckboxChild [data]="data" type="checkbox" name="" id="">
                              </td>
                              <td>
                                 <span [routerLink]="'/ecommerce/payout/payouts-requests/' + data.id"
                                    class="course-id">
                                    <span class="text-blue-600 cursor-pointer">
                                       {{ data.instructor.fullname }}
                                    </span>
                                 </span>
                              </td>
                              <td>
                                 {{data.no_of_transactions}}
                              </td>
                              <td>
                                 {{data.payment_method}}
                              </td>
                              <td class="max-w-[170px]">
                                 <span class="">
                                    {{ data.payout_type }}
                                 </span>
                              </td>
                              <td>
                                 <div class="course-id">
                                    {{ data.date_created| date:'d MMMM y' }}
                                    <div class="text-slate-400">{{ data.date_created| date:'h:mm:ss a' }}</div>
                                 </div>
                              </td>
                              <td>
                                 {{data.requested_amount | currency:data.currency_symbol}}
                              </td>
                              <td>
                                 {{data.status}}
                              </td>
                              <td>
                                 <div class="flex gap-2">
                                    <button *ngIf="data.status === 'requested'" (click)="requestConfirmation(approveAllModal, [data.id])" class="btn rounded-[4px] border btn-teal">
                                       <svg-icon src="@assets/images/icons/check.svg" class="btn-icon">
                                       </svg-icon>
                                    </button>
                                    <button *ngIf="data.status === 'requested' || data.status === 'ready'" class="btn border btn-secondary rounded-[4px]" (click)="requestConfirmation(deactivateAllModal, [data.id])">
                                       <svg-icon src="@assets/images/icons/no-symbol.svg" class="btn-icon">
                                       </svg-icon>
                                    </button>
                                 </div>
                              </td>
                           </tr>
                           <!-- <tr *ngIf="fetchingRequests">
                              <td colspan="999">
                                 <div class="flex justify-center" [thkLibProcessing]="true"></div>
                              </td>
                           </tr> -->
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </ng-template>

         <div class="card-body">
            <div class="courses-list relative">
               <ng-container *ngIf="isLoading">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
               </ng-container>
               <ng-container *ngIf="requestsPagination?.results?.length === 0 && !isLoading; else isItems">
                  <span class="text-center text-2xl my-11 block">No Items Founded!</span>
               </ng-container>
               <ng-template #isItems>
                  <ng-container *ngTemplateOutlet="listPayoutRequestAllPagination; context: { items: requestsPagination?.results }">
                  </ng-container>
               </ng-template>
               <div class="z-30 relative">
                  <app-common-pagination
                     (paginatorData)="paginationAction($event)"
                     (pageSize)="pageSizeAction($event)"
                     [config]="paginationConfig"
                     [type]="'dynamic'">
                  </app-common-pagination>
               </div>
            </div>
         </div>
      </div>
   </div>
<ng-template #notFound>
   <div *ngIf="requestsPagination" style="height: calc(100% - 108px);" class="flex flex-col items-center justify-center">
      <div class="max-w-md text-center">
         <h2 class="text-5xl text-[#9094FF] font-black">Oops!</h2>
         <h5 class="text-base text-neutral-600 font-normal">There is no payout request currently, please check later
         </h5>
      </div>
   </div>
</ng-template>

<common-modal #approveAllModal title="Approve Requests">
   <div content class="py-8 px-6 gap-6 flex flex-col">
      <div class="flex flex-col gap-2">
         <h5>Are you sure?</h5>
         <span *ngIf="confirmationSummary" class="text-slate-500 font-normal text-sm">This will approve {{confirmationSummary.total_payout_requests}} instructors payout request.</span>
      </div>

      <div>
         <span class="text-xs text-slate-500">Payout details</span>
         <app-payouts-confirmation-information *ngIf="approveAllModal.isOpened" [summary]="confirmationSummary" class="bg-slate-300">
            <app-messages [messages]="[{ type: 'warning', title: 'Warning', message: 'Executing this action will permanently hold any transaction in on-hold state, and it cannot be undone after payout approval.'}]"></app-messages>
         </app-payouts-confirmation-information>
      </div>

    <div class="flex flex-col gap-2">
      <div class="text-sm">Please type "<span
          class="text-bold">APPROVE</span>" to confirm this process</div>
      <div><input type="text" class="input-field w-full" ngModel
          #approvalConfirmInput></div>
    </div>
   </div>
   <div footer class="flex justify-end gap-2 p-4">
      <button class="btn btn-white" (click)="approveAllModal.close()">Cancel</button>
      <button [disabled]="approvalConfirmInput.value !== 'APPROVE'" class="btn btn-teal" [thkLibProcessing]="approvingMany" (click)="approveRequests(approveAllModal)">Yes, Confirm</button>
   </div>
</common-modal>

<common-modal #deactivateAllModal title="Deactivate Requests">
   <div content class="flex flex-col gap-6 py-8 px-6">
      <div class="flex flex-col gap-2">
         <h5>Are you sure?</h5>
         <span *ngIf="confirmationSummary" class="text-slate-500 font-normal text-sm">This will deactivate {{confirmationSummary.total_payout_requests}} instructors payout request.</span>
      </div>

      <div>
         <span class="text-xs text-slate-500">Payout details</span>
         <app-payouts-confirmation-information *ngIf="deactivateAllModal.isOpened" [summary]="confirmationSummary" class="bg-slate-300">
            <app-messages [messages]="[{ type: 'warning', title: 'Warning', message: 'Executing this action will permanently hold any transaction in on-hold state, and it cannot be undone after payout approval.'}]"></app-messages>
         </app-payouts-confirmation-information>
      </div>

      <div class="flex flex-col gap-2">
         <div class="text-sm">Please type "<span
            class="text-bold">DEACTIVATE</span>" to confirm this process</div>
         <div><input type="text" class="input-field w-full" ngModel
            #deactivateConfirmInput></div>
      </div>
   </div>
   <div footer class="flex justify-end gap-2 py-4 px-6">
      <button class="btn btn-white" (click)="deactivateAllModal.close()">Cancel</button>
      <button [disabled]="deactivateConfirmInput.value !== 'DEACTIVATE'" class="btn btn-danger" [thkLibProcessing]="deactivatingMany" (click)="deactivateRequests(deactivateAllModal)">Yes, Confirm</button>
   </div>
</common-modal>
<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
 </ng-template>