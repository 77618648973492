import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import videojs from 'video.js';
// import { tracks } from '../../../../../assets/video/texttracks';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, Subject } from 'rxjs';
import { LoaderService, VideoData } from 'thkee-common';
import '../../../../videojs/plugins/es/nuevo.js';
import '../../../../videojs/plugins/es/videojs.hotkeys.js';
import '../../../../videojs/plugins/es/videojs.thumbnails.js';
// declare var videojs: any; // Declare videojs as a global variable
import { environment } from '@env/environment';

export interface InputData {
  currentTime?: number;
  currentDuration?: number;
}
export interface SpriteThumbnail {
  slideImage: string;
  slideType?: string;
  slideWidth?: number;
  slideHeight?: number;
}
export interface LectureVideoCaption {
  id: number;
  label: string;
  srlang: string;
  src: string;
  kind: string;
  default: number;
  number: number;
}

@UntilDestroy()
@Component({
  selector: 'app-thk-video-player',
  templateUrl: './thk-video-player.component.html',
  styleUrls: ['./thk-video-player.component.scss'],
})
export class ThkVideoPlayerComponent implements OnInit, OnDestroy {
  @Output() event = new EventEmitter<{ action: string; data: InputData }>();
  @ViewChild('video', { static: false }) videoRef!: ElementRef;

  player: any;
  // @Input() sources: URLSType[] = [];
  @Input() sources$: Subject<VideoData> = new Subject<VideoData>();
  @Input() settingsButton: boolean = true;
  @Input() relatedMenu: boolean = true;
  @Input() relatedVideos: any[] = [];
  @Input() qualityMenu: boolean = true;
  @Input() buttonRewind: boolean = true;
  @Input() controlbar: boolean = true;
  @Input() theaterButton: boolean = true;
  @Input() autoplay: boolean = true;
  @Input() singlePlayer: boolean = false;
  @Input() enableHotkey: boolean = false;
  @Input() videoTimelinePosition: number = 0;
  // @Input() poster: string = '';
  @Input() poster: string = 'https://cdnzone.nuevodevel.com/video/hls/tos/tearsd.jpg';
  isSourceFound: boolean = false;
  @Input() lectureVideoCaptions: LectureVideoCaption[] = [];

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.sources$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe({
      next: (res: VideoData) => {
        if (res?.video_url?.length > 0) {
          this.isSourceFound = true;
          setTimeout(() => {
            this.initializePlayer(res);
          });
        } else {
          this.isSourceFound = false;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  private initializePlayer(videoData: VideoData) {
    // Video Source
    const sources = videoData?.video_url.map((video) => {
      let type;
      switch (video.type) {
        case 'mp4':
          type = 'video/mp4';
          break;
        case 'mpeg-dash':
          type = 'application/dash+xml';
          break;
        case 'm3u8':
          type = 'application/x-mpegURL';
          break;
        default:
          type = 'video/mp4';
      }
      return { src: video.url, type };
    });

    if (sources) {
      const videojs_options = {
        poster: this.poster,
        sources: sources,
        license: environment.videoJsLicense,
      };

      // window.videojs = videojs;

      if (this.videoRef?.nativeElement) {
        this.player = videojs(this.videoRef.nativeElement, videojs_options, () => {
          console.log('Player ready!');
        });
      } else {
        console.error('Video element not found');
      }
      this.player.aspectRatio('2:1');
      // this.player.autoplay(this.autoplay);

      // Video Thumbnail sprite
      // const videoThumbSprite = {
      //   slideImage: videoData?.thumbnails?.url,
      //   slideType: 'horizontal', //optional (vertical | horizontal)
      //   slideWidth: 160, //optional
      //   slideHeight: 90, //optional
      // };

      const nuevo_options = {
        video_id: 'Nuevo E1',
        url: 'https://www.nuevodevel.com',
        title: 'Video.js Angular 16 Demo (Hls,Captions,Chapters)',
        controlbar: this.controlbar,
        settingsButton: this.settingsButton,
        relatedMenu: this.relatedMenu,
        related: this.relatedVideos,
        qualityMenu: this.qualityMenu,
        buttonRewind: this.buttonRewind,
        resOnly: true,
        singlePlay: this.singlePlayer,
        theaterButton: this.theaterButton,
        ghostThumb: true,
      };

      this.player.nuevo(nuevo_options);
      if (this.enableHotkey) {
        this.player.hotkeys({
          volumeStep: 0.1,
          seekStep: 5,
          enableNumbers: false,
        });
      }

      this.player.on('ready', () => {
        this.player.currentTime(this.videoTimelinePosition);
      });

      // Thumbnail
      this.player.thumbnails({
        basePath: this.getBaseUrl(videoData?.thumbnails_vtt?.url),
        src: videoData?.thumbnails_vtt?.url,
      });

      this.player.on('loadeddata', () => {
        // Caption
        this.player.loadTracks(this.lectureVideoCaptions);
        this.emitEvent('loadedmetadata', {
          currentTime: this.player.currentTime(),
          currentDuration: this.player.duration(),
        });
      });

      this.player.on('play', () => {
        this.emitEvent('play', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
      });

      this.player.on('timeupdate', () => {
        this.emitEvent('progress', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
      });

      this.player.on('pause', () => {
        this.emitEvent('pause', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
      });

      this.player.on('ended', () => {
        this.emitEvent('ended', { currentTime: this.player.currentTime(), currentDuration: this.player.duration() });
      });

      this.player.on('mode', (event: any, mode: string) => {
        if (mode == 'large') {
          this.loaderService.loaderAction('theater-button-action', 'success');
        } else if (mode == 'normal') {
          this.loaderService.loaderAction('theater-button-action', 'loading');
        }
      });
    }
  }

  // Get base url from thumbnail url
  getBaseUrl(url: string): string {
    try {
      const cleanUrl = url.split('?')[0].split('#')[0];
      return cleanUrl.substring(0, cleanUrl.lastIndexOf('/') + 1);
    } catch (error) {
      return '';
    }
  }

  private emitEvent(action: string, data: InputData = {}) {
    this.event.emit({ action, data });
  }

  onPlay() {
    this.player.play();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
