import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationConfig } from '@shared';
import { PayoutActivationLists, PayoutActivationSummary, UserService } from '@shared/services';
import { firstValueFrom } from 'rxjs';
import { Pagination, PaginationService, UtilsService } from 'thkee-common';

const pageSize: number = 10;

interface QueryParams {
  page: number;
  page_size: number;
  search?: string;
  submited_date?: string;
  verified_date?: string;
}

@Component({
  selector: 'app-payout-activation',
  templateUrl: './payout-activation.component.html',
  styleUrls: ['./payout-activation.component.scss'],
})
export class PayoutActivationComponent implements OnInit {
  payoutActivationSummary!: PayoutActivationSummary;
  payoutActivation!: Pagination<PayoutActivationLists>;
  isLoading: boolean = false;
  isLoadingSummary: boolean = false;
  queryParams: QueryParams = { page: 1, page_size: pageSize };

  constructor(
    private userService: UserService,
    private paginationService: PaginationService,
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getPayoutActivationSummary();
    this.queryUpdater();
  }
  // Update query
  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getPayoutActivation(this.queryParams);
    });
  }

  private getPayoutActivationSummary() {
    this.isLoadingSummary = true;
    this.userService.getPayoutActivationSummary().subscribe({
      next: (res) => {
        this.isLoadingSummary = false;
        this.payoutActivationSummary = res;
      },
      error: (err) => {},
    });
  }

  private getPayoutActivation(queryParams = {} as object) {
    this.isLoading = true;
    this.userService.getPayoutActivation(queryParams).subscribe({
      next: (res) => {
        console.log('res', res);
        this.isLoading = false;
        this.payoutActivation = res;
        this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  search(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.search = inputElement.value;
    this.getPayoutActivation(this.queryParams);
  }

  selectSubmitDate(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.submited_date = inputElement.value;
  }

  selectVerifiedDate(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.verified_date = inputElement.value;
    this.getPayoutActivation(this.queryParams);
  }

  // Pagination
  paginationConfig: PaginationConfig = {
    pageCountSummary: true,
    pageSize: true,
    firstAndLast: true,
  };
  paginationAction(page_number: number) {
    this.queryParams.page = page_number;
    this.getPayoutActivation(this.queryParams);
  }

  pageSizeAction(value: number) {
    this.queryParams.page_size = value;
    this.queryParams.page = 1;
    this.getPayoutActivation(this.queryParams);
  }

}
