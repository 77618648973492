import { Feedback } from '../course2.model';
import { Auditable, PartialEntity } from '../utils.model';

export interface CourseCurriculumSourceV2 {
  id: string;
  title: string;
  position: number;
  subsections: any[];
  date_updated: string;
  source?: CourseCurriculumSourceV2;
}

export interface CourseCurriculumSectionV2 {
  id: string;
  title: string;
  position: number;
  subsections: SubsectionDetailV3[];
  date_updated: string;
  source?: CourseCurriculumSourceV2;
  course?: string;
}

// export interface CourseCurriculumResultV2 {
//   id: string;
//   chain_id: string;
//   title: string;
//   status: string;
//   sections: CourseCurriculumSectionV2[];
// }

export interface CourseCurriculumResponseV2 {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    course_date_updated: string;
    sections: CourseCurriculumSectionV2[];
  };
}

export interface QuizQuestionDetail2V3 {
  id: string;
  answers: QuizAnswerV3[];
  question: string;
  subsectionId: string;
  title: string;
  lecture: string;
}

export type QuizQuestionDetailV3 = QuizQuestionV3 & {
  answers: QuizAnswerV3[];
};
export type QuizDetailV3 = QuizV3 & {
  questions: QuizQuestionDetailV3[];
};

export type AssignmentDetailV3 = AssignmentV3 & {
  questions: AssignmentQuestionV3[];
};

export type QuizQuestionV3 = Auditable & {
  id: string;
  question: string;
  lecture?: string;
  quiz: string;
  source?: undefined;
};
export type QuizAnswerV3 = Auditable & {
  id: string;
  answer: string;
  is_correct?: boolean;
  expected?: string;
  quiz_question: string;
  source?: undefined;
  subsectionId?: string;
};

export type LectureDetailV3 = LectureV3 & {
  resources?: PartialEntity<LectureResourceV3>[];
};

export type SubsectionDetailV3 = PartialEntity<Omit<SubsectionV3, 'quiz' | 'assignment' | 'lecture'>> & {
  quiz?: PartialEntity<QuizDetailV3> & {
    feedback?: Feedback | boolean;
  };
  assignment?: PartialEntity<AssignmentDetailV3> & {
    feedback?: Feedback | boolean;
  };
  lecture?: PartialEntity<LectureV3> & {
    feedback?: Feedback | boolean;
  };
};

// export type SubsectionDetailV3 = {
//   id?: string;
//   position?: number;
//   date_created?: string;
//   date_updated?: string;
//   type?: string;
//   source?: string | null;
//   quiz?: Partial<QuizDetailV3> & {
//     feedback?: Feedback | boolean;
//   };
//   assignment?: Partial<AssignmentDetailV3> & {
//     feedback?: Feedback | boolean;
//   };
//   lecture?: Partial<LectureV3> & {
//     feedback?: Feedback | boolean;
//   };
// };

// export type SubsectionDetailV3 = {
//   id?: string;
//   position?: number;
//   date_created?: string;
//   date_updated?: string;
//   type?: string;
//   source?: string | null;

//   quiz?: {
//     id?: string;
//     title?: string;
//     date_updated?: string;
//     questions?: QuizQuestionDetailV3[];
//     feedback?: Feedback | boolean;
//     date_created?: string;
//   };

//   assignment?: {
//     id?: string;
//     title?: string;
//     due_date?: string;
//     submission_type?: string;
//     feedback?: Feedback | boolean;
//     date_created?: string;
//   };

//   lecture?: {
//     id?: string;
//     title?: string;
//     video_url?: string;
//     date_updated?: string;
//     feedback?: Feedback | boolean;
//     type?: LectureTypeV2;
//     date_created?: string;
//   };
// };
export type UploadStateTypeV2 =
  | 'init'
  | 'loading'
  | 'uploading'
  | 'upload-progress'
  | 'upload-complete'
  | 'upload-abort'
  | 'processing'
  | 'completed'
  | 'failed'
  | 'resume';

export type UploadStateV2 = {
  referenceId: string;
  fileKey: string;
  fileName: string;
  progressPercent: number;
  bytesUploaded: number;
  bytesTotal: number;
  isUploadComplete: boolean;
  fileType: string;
  status?: UploadStateTypeV2;
  error?: string;
};

export type LectureTypeV2 = 'video' | 'article';

export type LectureV3 = Auditable & {
  id: string;
  title: string;
  video?: string;
  video_id?: string;
  video_info?: StreamVideoInfoV3;
  article?: string;
  type?: LectureTypeV2;
  position?: number;
  preview?: boolean;
  published?: boolean;
  section: string;
  source?: {};
  video_url?: string;
  chain_id?: string;
  resources?: PartialEntity<LectureResourceV3>[];
  subsectionId?: string;
};

export type StreamVideoInfoV3 = {
  uid?: string;
  thumbnail?: string;
  playback?: { url: string };

  name?: string;
  size: string;
  type?: string;
  user?: string;
  error?: string;
  event?: string;
  course?: string;
  public?: string;
  status: string;
  lecture?: string;
  library?: string;
  duration: number;
  course_id?: string;
  lecture_id?: string;
  task_token?: string;
  content_type?: string;
};

export type LectureResourceV3 = Auditable & {
  id: string;
  file?: string;
  lecture: string;
  file_url?: string;
  label?: string;
};

export type SectionV3 = Auditable & {
  id: string;
  course: string;
  title: string;
  position?: number;
  duration?: string;
  source?: SectionV3;
};

export enum SubsectionTypeV3 {
  LECTURE = 'lecture',
  QUIZ = 'quiz',
  ASSIGNMENT = 'assignment',
}

// export type SubsectionTypeV3 = 'lecture' | 'quiz' | 'assignment';
export type SubsectionV3 = Auditable & {
  id: string;
  type: SubsectionTypeV3;
  position?: number;
  quiz?: string;
  lecture?: string;
  assignment?: string;
  section: string;
  source?: SubsectionV3;
};

export type QuizV3 = Auditable & {
  id: string;
  title: string;
  section: string;
  subsectionId?: string;
};

export interface QuizQuestionDetailsV3 {
  id: string;
  questions: QuizQuestionsV3[];
  date_updated: string;
  title: string;
  source: null;
  section: string;
  subsectionId: string;
}

export interface QuizQuestionsV3 {
  id: string;
  answers: QuizAnswerV3[];
  date_updated: string;
  question: string;
  source: null;
}

// Assignment
export type AssignmentV3 = Auditable & {
  id: string;
  title: string;
  section: string;
  source?: SectionV3 | string;
  subsectionId?: string;
};

export type AssignmentQuestionV3 = Auditable & {
  id: string;
  question: string;
  expected?: string;
  assignment: string;
  source?: SectionV3 | string;
};
