import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DrawerComponent, PaginationConfig, SharedModule, TaxCategoryLists } from '@shared';
import { TaxConfigService } from '@shared/services/price-management/tax-config.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { firstValueFrom, Subscription } from 'rxjs';
import { PaginationService, Params, ToastService } from 'thkee-common';
const pageSize: number = 10;

@Component({
  selector: 'app-price-tax-category',
  standalone: true,
  imports: [AngularSvgIconModule, RouterLink, ReactiveFormsModule, FormsModule, SharedModule],
  templateUrl: './price-tax-category.component.html',
  styleUrl: './price-tax-category.component.scss',
})
export class PriceTaxCategoryComponent implements OnInit, OnDestroy {
  taxCategoryLists: TaxCategoryLists[] = [];
  totalTaxCategory: number = 0;
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  @ViewChild('drawerSidebar') private drawerSidebar!: DrawerComponent;
  action: string = 'create';
  queryParams: Params = { page: 1, page_size: pageSize };

  // subscriptions 
  subscriptions = new Subscription();

  taxCategory = new FormGroup({});
  taxCategoryModel: TaxCategoryLists = {
    name: '',
    slug: '',
    display_order: undefined,
  };
  taxCategoryFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'flex gap-4 items-center',
      fieldGroup: [
        {
          template: '<label class="block font-bold w-40 text-left">Name</label>',
        },
        {
          key: 'name',
          type: 'input',
          className: 'w-full',
          props: {
            wrapClass: 'input-group mb-0 max-sm:mb-1',
            hideLabel: true,
            placeholder: 'Name',
            required: true,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'flex gap-4 items-center',
      fieldGroup: [
        {
          template: '<label class="block font-bold w-40 text-left">Category Code</label>',
        },
        {
          key: 'slug',
          type: 'input',
          className: 'w-full',
          props: {
            wrapClass: 'input-group mb-0 max-sm:mb-1',
            hideLabel: true,
            placeholder: 'Category Code',
            required: true,
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'flex gap-4 items-center',
      fieldGroup: [
        {
          template: '<label class="block font-bold w-40 text-left">Display Order</label>',
        },
        {
          key: 'display_order',
          type: 'input',
          className: 'w-full',
          props: {
            wrapClass: 'input-group mb-0 max-sm:mb-1',
            hideLabel: true,
            placeholder: 'Display Order',
            required: true,
          },
        },
      ],
    },
  ];

  constructor(
    private taxConfigService: TaxConfigService,
    private router: Router,
    private toastService: ToastService,
    private paginationService: PaginationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.queryUpdater()
  }

  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getTaxCategoryLists();
    });
  }
  getTaxCategoryLists() {
    this.isLoading = true;
    this.subscriptions.add(
      this.taxConfigService.getTaxCategoryLists(this.queryParams).subscribe({
        next: (res) => {
          this.taxCategoryLists = res.results;
          this.totalTaxCategory = res.count;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (error) => {
          this.toastService.error({
            message: error,
          });
          this.isLoading = false;
        },
      })
    )
  }

  paginate(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getTaxCategoryLists();
  }

  paginationConfig: PaginationConfig = {
    pageCountSummary: true,
    pageSize: true,
    firstAndLast: true,
  };
    
  pageSizeAction(value: number) {
    this.queryParams.page_size = value;
    this.queryParams.page = 1
    this.getTaxCategoryLists();
  }

  paginationAction(page_number: number) {
    this.queryParams.page = page_number;
    this.getTaxCategoryLists();
  }

  taxCategoryId!: number;
  submit() {
    this.isSubmitted = true;
    if (this.action === 'create') {
      this.taxConfigService.createTaxCategory(this.taxCategoryModel).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          this.taxCategoryLists.push(res);
          this.drawerSidebar.close();
        },
        error: ({ error }) => {
          this.isSubmitted = false;
          error.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      });
    } else {
      this.taxConfigService.updateTaxCategory(this.taxCategoryModel, this.taxCategoryId.toString()).subscribe({
        next: (res) => {
          this.isSubmitted = false;
          // this.taxCategoryLists.push(res);
          this.drawerSidebar.close();
        },
        error: ({ error }) => {
          this.isSubmitted = false;
          error.errors.map((error: { detail: string }) =>
            this.toastService.error({
              message: error.detail,
            })
          );
        },
      });
    }
  }

  add() {
    this.taxCategory.reset();
    this.drawerSidebar.open();
    this.action = 'create';
  }

  update(category: TaxCategoryLists) {
    this.taxCategoryId = category.id || 0;
    this.taxCategory.patchValue(category);
    this.drawerSidebar.open();
    this.action = 'update';
  }

  reset() {
    this.taxCategory.reset();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
