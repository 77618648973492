export interface CourseDetailsModelV2 {
  id: string;
  code: string;
  chain_id: string;
  title: string;
  slug: string;
  is_free: boolean;
  pricing: string;
  subtitle: string;
  desc: string;
  image: string;
  is_promote: boolean;
  skill_level: string;
  category: string;
  subcategory: string;
  language: string;
  topics: string[];
  course_type: string;
  status?: CourseStatusV2;
  included_in_promo: boolean;
  date_published: string | null;
  is_deleted: boolean;
  project: string;
  version: string;
  date_updated: string;
  date_created: string;
  category_name: string;
  unpublished_project: UnpublishedProject;
  source: string;
  video_info: videoInfoV2;
  user: UserV2 | any;
  image_url?: string;
  currency?: number;
  currency_preview?: number;
  promo_video?: string;
  // Extra For admin
  is_latest?: boolean;
  is_current?: boolean;
  currency_symbol?: string; //OP
  currency_code?: string; //OP
  course_sold?: string;
  average_rating?: string;
  total_reviews?: string;
  price_snapshot?: string;
  currency_snapshot?: string;
}

export interface UnpublishedProject {
  id: string;
  published_course: string;
  unpublished_course: string;
  state: string;
  has_substantial_changes: boolean;
  user: number;
  date_created: string;
  date_updated: string;
}

export interface UserV2 {
  id: number;
  first_name: string;
  last_name: string;
  bio: string;
  photo: string;
  fullname: string;
  photo_url: string;
  headline: string;
  language: string;
  is_active: boolean;
  public_profile: string;
}

export interface videoInfoV2 {
  library?: string;
  status: string;
  event?: string;
  error?: string;
  name?: string;
  size?: string;
  type?: string;
  duration?: number;
  task_token?: string;
  content_type?: string;
}

export enum CourseStatusV2 {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  REJECTED = 'rejected',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export interface ActionsParams {
  fixed_source: boolean;
}

export interface CourseActionsPayload {
  courseId: string;
  params?: ActionsParams;
}

export interface EditorImageInfo {
  id: string;
  user: number;
  course: string;
  date_created: string;
  date_updated: string;
  height: number;
  width: number;
  image: string;
  object_id: string | null;
  content_type: string | null;
}
