import { NgIf } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'thk-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  standalone: true,
  imports: [NgIf],
})
export class AvatarComponent implements OnInit {
  @Input() url?: string;
  @Input() title?: string = '';

  @HostBinding('class')
  @Input()
  sizeClasses = 'h-8 w-8 text-xs';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  get titleText() {
    const [first, second] = (this.title || '').split(' ');
    if (second) {
      return [first[0], second[0] || first[1]].filter(Boolean).join('') || 'NA';
    } else {
      return [first[0] + first[1]].filter(Boolean).join('') || 'NA';
    }
  }

  sanitizeUrl(url?: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url ?? 'assets/images/static/fallback.png');
  }
}
