import {
  AnnotationsByProjectResultV2,
  CourseCurriculumResponseV2,
  CourseDetailsModelV2,
  CourseLearnerModelV2,
  CoursesVersionHistory,
  FeedbackStateV2,
  UploadStateV2,
} from '../../models';
export type ActivityStateV2 =
  | 'type_lecture'
  | 'type_quiz'
  | 'type_assignment'
  | 'lecture_video'
  | 'lecture_article'
  | 'video_library';

export const initialFeedbackStateV2: FeedbackStateV2 = {
  isFeedbackModalOpen: false,
  fieldId: '',
  fieldKey: '',
  fieldModel: undefined,
  fieldValue: '',
};

// Initial State
export const initialCourseStateV2: CourseStateV2 = {
  courseDetails: {
    id: '',
    code: '',
    chain_id: '',
    title: '',
    slug: '',
    is_free: false,
    pricing: '',
    subtitle: '',
    desc: '',
    image: '',
    is_promote: false,
    skill_level: '',
    category: '',
    subcategory: '',
    language: '',
    topics: [],
    course_type: '',
    included_in_promo: false,
    date_published: null,
    is_deleted: false,
    project: '',
    version: '',
    date_updated: '',
    date_created: '',
    category_name: '',
    unpublished_project: {
      id: '',
      published_course: '',
      unpublished_course: '',
      state: '',
      has_substantial_changes: false,
      user: 0,
      date_created: '',
      date_updated: '',
    },
    source: '',
    video_info: {
      status: '',
    },
    user: {
      id: 0,
      first_name: '',
      last_name: '',
      bio: '',
      photo: '',
      fullname: '',
      photo_url: '',
      headline: '',
      language: '',
      is_active: false,
      public_profile: '',
    },
    image_url: '',
    currency: 0,
    currency_preview: 0,
  },
  courseLerner: {
    id: '',
    title: '',
    unpublished_project: [],
    learners: [],
    requirements: [],
    objectives: [],
  },
  courseCurriculum: {
    count: 0,
    next: null,
    previous: null,
    results: {
      course_date_updated: '',
      sections: [],
    },
  },
  uploads: {},
  selectedActivityId: '',
  selectedSectionId: '',
  selectedSubsectionId: '',
  activityState: undefined,
  isActivityModalOpen: false,
  annotations: {
    annotations: [],
  },
  feedback: initialFeedbackStateV2,
  coursesVersionHistory: {
    version: '',
    previous_version: '',
    status: '',
    date_updated: '',
    date_created: '',
    history: [],
  },
};

export interface CourseStateV2 {
  courseDetails: CourseDetailsModelV2;
  courseLerner: CourseLearnerModelV2;
  courseCurriculum: CourseCurriculumResponseV2;
  uploads: { [referenceId: string]: UploadStateV2 };
  selectedActivityId: string;
  activityState: ActivityStateV2 | undefined;
  annotations: AnnotationsByProjectResultV2;
  isActivityModalOpen: boolean;
  selectedSectionId: string;
  selectedSubsectionId: string;

  feedback: FeedbackStateV2;
  coursesVersionHistory: CoursesVersionHistory;
}
