import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  DrawerComponent,
  INSTRUCTOR_DRAWER_TAB,
  INSTRUCTOR_SETTINGS_DRAWER_TAB,
  ModalComponent,
  PaginationConfig,
  PRODUCT_DRAWER_TAB,
  RefundQueryParams,
  TabItem,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import {
  CombinationSingleInputItem,
  CombinationSingleInputValue,
} from '@shared/components/combination-single-input/combination-single-input.component';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import * as _ from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  map,
  of,
  switchMap,
} from 'rxjs';
import {
  AdminHoldTransactionQuery,
  AdminHoldTransactionsSummary,
  AdminPayoutTransaction,
  FileProps,
  LANGUAGES,
  Pagination,
  PaginationService,
  QueryStringFilterService,
  RouterStoreService,
  ToastService,
} from 'thkee-common';
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-hold-transaction',
  templateUrl: './hold-transaction.component.html',
  styleUrls: ['./hold-transaction.component.scss'],
  providers: [QueryStringFilterService.forComponent()],
})
export class HoldTransactionComponent implements OnInit, AfterViewInit {
  //Product TAB
  productTabLists: TabItem[] = PRODUCT_DRAWER_TAB;

  //Student TAB
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;

  //Student Settings TAB
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;

  search: CombinationSingleInputValue = { key: 'id' };
  // Pageination items
  isLoading: Boolean = false;
  queryParams: RefundQueryParams = { page: 1, page_size: pageSize };
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale Price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'sale_price_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '-',
          },
        },
        {
          template: '-',
        },
        {
          key: 'sale_price_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '-',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Net Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'net_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '-',
          },
        },
        {
          template: '-',
        },
        {
          key: 'net_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '-',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Instructor Earning</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'instructor_share_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '-',
          },
        },
        {
          template: '-',
        },
        {
          key: 'instructor_share_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '-',
          },
        },
      ],
    },
  ];
  transactionsPagination?: Pagination<AdminPayoutTransaction>;

  // Create product form
  productForm = new FormGroup({});
  productFormModel: any = {};
  productFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'card block py-9 px-6 mb-4',
      fieldGroup: [
        {
          className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
          template: '<h5>General</h5>',
        },
        {
          key: 'title',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: '#SAP MM Material Management',
          props: {
            inputAppendClass: ['border-0', 'bg-transparent', 'bg-none'],
            label: 'Title',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Learn Blender in 60 minutes',
            disabled: true,
            tips: 'This is a tip message for course title',
          },
          validation: {
            messages: {
              minLength: "Title can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'subtitle',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: 'Introduction to SAP MM Material Management',
          props: {
            label: 'Subtitle',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Introduction to SAP MM Material Management!',
            disabled: true,
            tips: 'This is a tip message for course sub title',
          },
          validation: {
            messages: {
              minLength: "This field can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'topics',
          type: 'select',
          defaultValue: ['uiux', 'frontend'],
          wrappers: ['feedback-field'],
          props: {
            label: 'Topics',
            placeholder: 'Choose topic',
            multiple: true,
            stayPlaceholder: true,
            disabled: true,
            tips: 'This is a tip message for course title',
            stylish: true,
            options: [
              { label: 'UI/UX', value: 'uiux' },
              { label: 'Frontend', value: 'frontend' },
              { label: 'Backend', value: 'backend' },
            ],
          },
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
          fieldGroup: [
            {
              key: 'skill_level',
              type: 'select',
              defaultValue: 'Beginner',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Level',
                placeholder: 'Choose a level',
                stylish: true,
                options: [
                  { label: 'All', value: 'All' },
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Expert', value: 'Expert' },
                ],
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6',
          fieldGroup: [
            {
              key: 'category',
              type: 'select',
              defaultValue: 'business',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Category',
                placeholder: 'Choose a category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Business', value: 'business' },
                  { label: 'Design', value: 'design' },
                  { label: 'Language', value: 'language' },
                ],
              },
              expressions: {},
            },
            {
              key: 'subcategory',
              type: 'select',
              defaultValue: 'graphic-design',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Sub category',
                placeholder: 'Choose sub-category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Graphic Design', value: 'graphic-design' },
                  { label: 'Web Design', value: 'web-design' },
                  { label: 'UI/UX Design', value: 'uiux-design' },
                ],
              },
              expressions: {},
            },
          ],
        },
      ],
    },

    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'image',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Image Cover',
        placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
      } as FileProps,
      expressions: {},
    },
    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'promo_video',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Promotional Video',
        placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
        preview: true,
        previewType: 'video',
        hideLabel: false,
        allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
        onUpload: (upload, field) => {
          // log.debug('upload: ', upload);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadStart({
          //       referenceId: `${courseId}-${field.key}`,
          //       fileName: upload.name,
          //       fileType: upload.type,
          //     })
          //   );
          // });
        },
        onProgress: (progress, field) => {
          // log.debug('progress: ', progress);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadProgress({
          //       referenceId: `${courseId}-${field.key}`,
          //       bytesTotal: progress.bytesTotal,
          //       bytesUploaded: progress.bytesUploaded,
          //       progressPercent: progress.progressPercent,
          //     })
          //   );
          // });
        },
        onComplete: (field) => {
          // log.debug('complete');
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadComplete({
          //       referenceId: `${courseId}-${field.key}`,
          //     })
          //   );
          // });
        },
      } as FileProps,
      expressions: {
        // 'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
        //   map(([course, userId]) => ({
        //     user: userId,
        //     course: course.id,
        //   })),
        //   distinctUntilChanged()
        // ),
        // 'props.previewUrl': this.course$.pipe(
        //   map((course) => {
        //     return course.promo_video_url;
        //   }),
        //   distinctUntilChanged()
        // ),
        // 'props.feedback': this.getFeedbackCount('promo_video'),
      },
    },
  ];
  page = 1;
  size = 5;
  holdingTran?: AdminPayoutTransaction;

  handleSearch(event: CombinationSingleInputValue) {
    if (event.value) {
      this.queryFilterService.patch({ search: [event.key, event.value].join(':') });
      return;
    }

    this.queryFilterService.remove(['search']);
  }
  searchOptions: CombinationSingleInputItem[] = [
    { id: 'id', title: 'ID', placeholder: 'Enter ID' },
    { id: 'name', title: 'Instructor Name', placeholder: 'Enter Instructor Name' },
  ];
  summary?: AdminHoldTransactionsSummary;

  isUnholding = false;

  constructor(
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private queryFilterService: QueryStringFilterService<AdminHoldTransactionQuery>,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.getHoldTransactionSummary();
    this.queryUpdater();
    this.routerStore.getParams().subscribe((params) => {
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Hold Transactions',
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }

  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getTransactions();
    });
  }

  ngAfterViewInit(): void {
    this.setupQueryFilter();
  }

  openUnholdConfirmationModal(tran: AdminPayoutTransaction, modal: ModalComponent) {
    this.holdingTran = tran;
    modal.open();
  }

  closeUnholdConfirmationModal() {
    this.holdingTran = undefined;
  }

  unholdTransaction(modal: ModalComponent) {
    if (!this.holdingTran) {
      return;
    }

    this.isUnholding = true;
    this.payoutService
      .unholdTransaction(this.holdingTran.id)
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.isUnholding = false;
        this.holdingTran = undefined;

        if (ok) {
          modal.close();
          this.triggerFetchingTransactions.next(true);
          this.toastService.message({
            type: 'message',
            message: 'Unhold transaction successfully',
          });
        }
      });
  }

  // paginate(event: PaginationChange) {
  //   this.queryFilterService.patch(event);
  // }

  paginationConfig: PaginationConfig = {
    pageCountSummary: true,
    pageSize: true,
    firstAndLast: true,
  };

  pageSizeAction(value: number) {
    this.isLoading = true;
    this.queryParams.page_size = value;
    // this.getTransactions();
    this.queryParams.page = 1;
    this.queryFilterService.patch({ page_size: value, page: 1 });
  }

  paginationAction(page_number: number) {
    this.isLoading = true;
    this.queryParams.page = page_number;
    // this.getTransactions();
    this.queryFilterService.patch({ page: page_number });
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  transactionData?: AdminPayoutTransaction;
  openTransactionDrawer(data: AdminPayoutTransaction, drawer: DrawerComponent) {
    this.transactionData = data;
    drawer.open();
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 2,
      title: 'Total amount of hold transactions.',
      value: 219,
      type: 'currency',
      tooltip: 'Total amount of held transactions',
    },
    {
      id: 4,
      title: 'Total Hold Transactions',
      value: 28,
      type: 'number',
      tooltip: 'Total number of held transactions',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      boldby: 'Frank',
    },
    {
      id: '123',
      product: {
        id: 'p002',
        title: 'Web 101 Fundamental...',
      },
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      boldby: 'Ibrahim',
    },
    {
      id: '123',
      product: {
        id: 'p003',
        title: 'Web 101 Fundamental...',
      },
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      status: 'Forced Refund',
      boldby: 'Emran',
    },
  ];

  private getHoldTransactionSummary() {
    this.payoutService.getHoldTransactionsSummary().subscribe((data) => (this.summary = data));
  }

  private triggerFetchingTransactions = new BehaviorSubject(true);
  private getTransactions() {
    this.isLoading = true;
    combineLatest([this.queryFilterService.valueChanges, this.triggerFetchingTransactions])
      .pipe(
        debounceTime(400),
        map(([query]) => query),
        distinctUntilChanged(),
        switchMap((query) => {
          return this.payoutService.getHoldTransactions(query);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res) => {
          this.transactionsPagination = res;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      });
  }

  private setupQueryFilter() {
    this.queryFilterService.initialValue.subscribe((query) => {
      if (query.search) {
        const [key, value] = query.search.split(':');
        this.search = { key, value };
      }

      this.page = Number(query.page || 1);
      this.size = Number(query.page_size || 5);
      this.filterForm.patchValue(query);
    });

    this.filterForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => this.queryFilterService.patch(value as any));
  }
}
