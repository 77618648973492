import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  NgZone,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SelectControlValueAccessor } from '@angular/forms';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { ToastService } from '../../../services';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  multiple?: boolean;
  stayPlaceholder?: boolean;
  stylish?: boolean;
  showSelected?: boolean;
  selectedDisplayText?: (options: any[]) => string;
  inputAppendClass?: string[];
  newItem?: string;
  activeSearch?: boolean;
  isSearching?: boolean;
  maxItems?: number;
  compareWith: (o1: any, o2: any) => boolean; // Make it required
  onSearch?: (value: string) => void;
  onEnter?: (value: string) => void;
  onSelect?: (value: string) => void;
  onSelectExisting?: (value: string) => void;
}

export interface FormlySelectFieldConfig extends FormlyFieldConfig<SelectProps> {
  type: 'select' | Type<FormlyFieldSelect>;
}

interface Option {
  label: string; // Define the type of label
  value: string; // Define the type of value
}

@Component({
  selector: 'app-formly-field-select',
  template: `
    <ng-template #fieldTypeTemplate>
      <ng-container *ngIf="!props.stylish; else customSelect">
        <ng-container *ngIf="!props.multiple; else multiSelect">
          <ng-container *ngTemplateOutlet="singleSelect"></ng-container>
        </ng-container>
      </ng-container>

      <ng-template #customSelect>
        <ng-container *ngIf="!props.multiple; else multiCustomSelect">
          <ng-container *ngTemplateOutlet="singleCustomSelect"></ng-container>
        </ng-container>
      </ng-template>

      <ng-template #singleSelect>
        <select
          class="input-select"
          [formControl]="formControl"
          [compareWith]="props.compareWith"
          [class.is-invalid]="showError"
          [formlyAttributes]="field"
        >
          <option *ngIf="props.placeholder" [ngValue]="undefined">{{ props.placeholder }}</option>
          <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
                {{ opt.label }}
              </option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-container>
        </select>
      </ng-template>

      <ng-template #multiSelect>
        <select
          class="input-select"
          multiple
          [formControl]="formControl"
          [compareWith]="props.compareWith"
          [class.is-invalid]="showError"
          [formlyAttributes]="field"
        >
          <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group; else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">
                {{ opt.label }}
              </option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-container>
        </select>
      </ng-template>

      <ng-template #singleCustomSelect>
        <div class="select2 relative w-full" (ClickOutside)="handleClickOutside()">
          <button
            type="button"
            (click)="showDropdown = !showDropdown"
            class=""
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
            [disabled]="props.disabled"
            [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
            class="min-h-10 rtl:text-right"
          >
            <div class="selected-item truncate rtl:text-right" *ngIf="selectedLabel">{{ selectedLabel }}</div>
            <div class="selected-item placeholder truncate text-slate-500 rtl:text-right" *ngIf="!selectedLabel">
              {{ props.placeholder }}
            </div>
            <svg
              class="pointer-events-none absolute inset-y-0 mx-3 flex h-full w-5 items-center text-gray-400 transition-all ltr:right-0 rtl:left-0"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              [ngClass]="{ 'rotate-180': showDropdown }"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </button>
          <!-- Dropdown Items -->
          <ul
            [ngClass]="showDropdown ? 'h-auto opacity-100' : 'h-0 opacity-0'"
            [class.!pt-0]="props.activeSearch"
            class="select-items"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-option-3"
          >
            <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
              <ng-container *ngIf="props.activeSearch">
                <li class="sticky top-0 z-30 bg-white px-2.5 py-2">
                  <input
                    (keyup)="handleKeyUp($event)"
                    (keyup.enter)="handleKeyEnter($event)"
                    [(ngModel)]="searchInputValue"
                    class="w-full rounded-md border border-slate-300 px-2.5 py-2 outline-none ltr:pr-[150px] rtl:pl-[150px]"
                    type="text"
                    i18n-placeholder
                    placeholder="Search"
                  />
                  <div class="absolute top-0 flex h-full w-fit flex-row items-center gap-2 ltr:right-6 rtl:left-6">
                    <svg
                      class="!m-0 mx-4 w-4 items-center"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </div>
                </li>
              </ng-container>
              <ng-container *ngFor="let opt of opts | thkLibFilter : { label: searchInputValue }">
                <li
                  class="item"
                  id="listbox-{{ opt.value }}"
                  role="option"
                  (click)="selectDropdown(opt.value, opt.label)"
                >
                  {{ opt.label }}
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <div class="hidden"><ng-container *ngTemplateOutlet="singleSelect"></ng-container></div>
        </div>
      </ng-template>

      <ng-template #multiCustomSelect>
        <div class="select2 multiple relative w-full" (ClickOutside)="handleClickOutside()">
          <div class="relative">
            <button
              type="button"
              (click)="showDropdown = !showDropdown"
              class=""
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              [disabled]="props.disabled"
              [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
              class="rtl:!text-right"
            >
              <div class="selected-items inline" *ngIf="select2Multi.length">
                <ng-container *ngIf="props.selectedDisplayText; else defaultDisplayText">
                  {{ props.selectedDisplayText!(select2Multi) }}
                </ng-container>
                <ng-template #defaultDisplayText>
                  <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
                    <ng-container *ngFor="let opt of opts">
                      <div class="selected-item" [class.!hidden]="!isSelected(opt.value)">
                        <div class="flex items-center gap-1">
                          <div class="truncate">{{ opt.label }}</div>
                          <div (click)="removeMultiDropdown(opt.value)" [class.hidden]="props.disabled">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="h-3 w-3 ltr:right-0 rtl:left-0"
                            >
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>

              <div
                class="selected-item placeholder inline truncate text-slate-500"
                *ngIf="!select2Multi.length && !props.stayPlaceholder"
              >
                {{ props.placeholder }}
              </div>

              <div
                class="selected-item placeholder inline inline truncate text-neutral-500"
                [class.leading-8]="select2Multi.length"
                [class.hidden]="(props.disabled && select2Multi.length) || select2Multi.length === totalOptions"
                *ngIf="props.stayPlaceholder"
              >
                {{ props.placeholder }}
              </div>
              <svg
                class="pointer-events-none absolute inset-y-0 mx-3 flex h-full w-5 items-center text-gray-400 transition-all ltr:right-0 rtl:left-0"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                [ngClass]="{ 'rotate-180': showDropdown }"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <!-- Dropdown Items -->
            <ul
              [ngClass]="showDropdown ? 'h-auto opacity-100' : 'h-0 opacity-0'"
              class="select-items"
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <li *ngIf="props.activeSearch" class="relative px-2.5 py-2">
                <input
                  (keyup)="handleKeyUp($event)"
                  (keyup.enter)="handleKeyEnter($event)"
                  [(ngModel)]="searchInputValue"
                  class="w-full rounded-md border border-slate-300 px-2.5 py-2 outline-none ltr:pr-[150px] rtl:pl-[150px]"
                  type="text"
                  i18n-placeholder
                  placeholder="Search"
                />
                <div class="absolute top-0 flex h-full w-fit flex-row items-center gap-2 ltr:right-6 rtl:left-6">
                  <svg
                    class="!m-0 mx-4 w-4 items-center rtl:scale-x-[-1]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                    />
                  </svg>
                  <span class="text-xs text-slate-400" i18n>Press <b>Enter</b> to Add</span>
                </div>
              </li>
              <li
                *ngIf="props.activeSearch && props.isSearching"
                class="relative px-2.5 py-2"
                [thkLibProcessing]="true"
                size="sm"
              ></li>
              <ng-container *ngIf="props.options | formlySelectOptions : field | async as opts">
                <ng-container *ngFor="let opt of opts">
                  <li
                    class="item"
                    id="listbox-option-0"
                    role="option"
                    (click)="selectMultiDropdown(opt.value)"
                    [class.hidden]="!props.showSelected && isSelected(opt.value)"
                  >
                    <div class="relative w-full" [ngClass]="props.showSelected ? 'ltr:pl-5 rtl:pr-5' : ''">
                      <span
                        *ngIf="props.showSelected && isSelected(opt.value)"
                        class="text-primary absolute top-[50%] translate-y-[-50%] transform ltr:left-0 rtl:right-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="h-4 w-4"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                        </svg>
                      </span>
                      {{ opt.label }}
                    </div>
                  </li>
                </ng-container>
                <li
                  *ngIf="props.activeSearch && searchInputValue.length > 2"
                  class="relative cursor-pointer px-2.5 py-2"
                >
                  <span (click)="onSelectProposedTopic(searchInputValue)" i18n>
                    Propose new topic: " <b>{{ searchInputValue }}</b
                    >"
                  </span>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="hidden"><ng-container *ngTemplateOutlet="multiSelect"></ng-container></div>
        </div>
      </ng-template>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldSelect extends FieldType<FieldTypeConfig<SelectProps>> implements OnInit, AfterContentChecked {
  showDropdown: boolean = false;
  selectedLabel: string = '';
  totalOptions: number = 0;

  override defaultOptions = {
    props: {
      compareWith(o1: any, o2: any) {
        return o1 === o2;
      },
    },
  };

  // workaround for https://github.com/angular/angular/issues/10010
  /**
   * TODO: Check if this is still needed
   */
  @ViewChild(SelectControlValueAccessor) set selectAccessor(s: any) {
    if (!s) {
      return;
    }

    const writeValue = s.writeValue.bind(s);
    if (s._getOptionId(s.value) === null) {
      writeValue(s.value);
    }

    s.writeValue = (value: any) => {
      const id = s._idCounter;
      writeValue(value);
      if (value === null) {
        this.ngZone.onStable
          .asObservable()
          .pipe(take(1))
          .subscribe(() => {
            if (
              id !== s._idCounter &&
              s._getOptionId(value) === null &&
              s._elementRef.nativeElement.selectedIndex !== -1
            ) {
              writeValue(value);
            }
          });
      }
    };
  }

  constructor(private ngZone: NgZone, hostContainerRef: ViewContainerRef, private toastService: ToastService) {
    super(hostContainerRef);
    this.props.stylish = true;
  }

  ngOnInit(): void {
    // this.props.stylish = true;
    this.totalOptions = _.size(this.props.options);
    if (this.formControl.value) {
      // let selectedOption = _.find(this.props.options, { value: this.formControl.value });
      // const selectedOption = _.find(this.props.options, { value: this.formControl.value }) as Option;
      // Inside your ngOnInit or relevant method
      const selectedOption = _.find(this.props.options as Option[], { value: this.formControl.value });

      if (selectedOption) this.selectedLabel = selectedOption['label'];
      this.select2Multi = this.formControl.value;
    }

    this.field.formControl.valueChanges.subscribe((value) => {
      if (!value || value == '') {
        // Update selected label state to blank if value is set to null or blank
        this.selectedLabel = '';
      }
    });
  }

  ngAfterContentChecked(): void {
    if (this.props.newItem) {
      this.selectMultiDropdown(this.props.newItem);
      this.props.newItem = '';
    }
  }

  selectDropdown(value: any, label?: string) {
    this.selectedLabel = label ? label : '';
    this.formControl.setValue(value);
    this.showDropdown = false;
  }

  select2Multi: any[] = [];
  isSelected(value?: string) {
    if (value) {
      return _.includes(this.select2Multi, value);
    }
    return false;
  }

  selectMultiDropdown(value?: string) {
    if (this.props.maxItems && this.select2Multi.length >= this.props.maxItems) {
      this.toastService.error({
        message: $localize`You cannot select more than ${this.props.maxItems} items.`,
      });
    } else {
      if (value) {
        if (this.select2Multi.includes(value)) {
          return this.removeMultiDropdown(value);
        }
        this.select2Multi = [...this.select2Multi, value];
        this.onSelectExisting(value);
      }
      this.select2Multi = _.uniq(this.select2Multi);
      this.formControl.setValue(this.select2Multi);
      this.showDropdown = true;
    }
  }

  removeMultiDropdown(value?: string) {
    if (value) {
      try {
        this.select2Multi = _.remove(this.select2Multi, (q, w) => {
          return q != value;
        });
      } catch (error) {
        const indexToRemove = this.select2Multi.indexOf(value);
        this.select2Multi = _.values(_.omit(this.select2Multi, [indexToRemove]));
      }
    }
    this.formControl.setValue(this.select2Multi);
    this.showDropdown = true;
  }

  handleClickOutside() {
    this.showDropdown = false;
  }

  // Search
  searchInputValue: string = '';
  handleKeyUp(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (this.props?.onSearch) {
      this.props.onSearch(inputElement.value);
    }
  }

  handleKeyEnter(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (this.props?.onEnter) {
      this.searchInputValue = '';
      this.props.onEnter(inputElement.value);
    }
  }

  onSelectProposedTopic(value: string) {
    if (this.props?.onSelect) {
      this.searchInputValue = '';
      this.props.onSelect(value);
    }
  }

  onSelectExisting(value: string) {
    if (this.props?.onSelectExisting) {
      this.searchInputValue = '';
      this.props.onSelectExisting(value);
    }
  }
}
