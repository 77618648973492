<div class="course-overview p-4 pt-0">
   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-col gap-4">
         <div *ngIf="!summary" class="flex justify-center w-full py-10" [thkLibProcessing]="true"></div>
         <ng-container *ngIf="summary">
            <div class="flex gap-4">
               <app-metric-card *ngFor="let item of summaryItems.slice(0, 4)"
                  class="w-full"
                  [data]="{ title: item.title, value: item.isCurrency ? (summary[item.field] | currency) : summary[item.field] }"></app-metric-card>
            </div>
            <div class="flex gap-4">
               <app-metric-card *ngFor="let item of summaryItems.slice(4, 7)"
                  class="w-full"
                  [data]="{ title: item.title, value: item.isCurrency ? (summary[item.field] | currency) : summary[item.field] }"></app-metric-card>
            </div>
         </ng-container>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="showingDropdown === 'filter' ? '' : 'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <thk-date-range-picker ngModel (ngModelChange)="filterByDate($event)" format="YYYY-MM-DD" placeholder="Select range date"></thk-date-range-picker>
         <app-combination-single-input [keys]="searchOptions" [model]="searchValue" (valueChanges)="search($event)"></app-combination-single-input>
      </div>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex items-center gap-1">
               <h6>All Payouts</h6>
               <div *ngIf="paidPayoutsPagination" class="tag tag-sm">{{paidPayoutsPagination.count}} Payee</div>
            </div>
            <div class="actions flex flex-row gap-4">
               <!-- <button class="btn btn-transparent">
                  <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
                  <span>Filters</span>
               </button> -->
               <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
                  <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
                  <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
               </button>
               <!-- <button class="btn-neutral px-3">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button> -->
            </div>
         </div>
         <ng-template #paidPayout>
            <div class="card-body">
               <div class="courses-list">
                  <!-- Table -->
                  <div class="datagrid-table relative overflow-x-auto">
                     <table class="w-full text-left">
                        <thead class="heading">
                           <tr>
                              <th scope="col" class="">
                                 <div class="col-name">Payout ID</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Instructor</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Request date</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Payout Type</div>
                              </th>
                              <th scope="col" class="w-28">
                                 <div class="col-name">Period</div>
                              </th>
                              <th scope="col" class="w-28">
                                 <div class="col-name">Transactions</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Payout Method</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Payout Amount</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Status</div>
                              </th>
                              <th scope="col" class="">
                                 <div class="col-name">Receipt</div>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr class="hover:cursor-pointer" *ngFor="let data of paidPayoutsPagination?.results">
                              <td>
                                 <span class="course-id">
                                    <span class="text-blue-600 cursor-pointer">
                                       {{ data.id }}
                                    </span>
                                 </span>
                              </td>
                              <td>
                                 <span [routerLink]="'/ecommerce/payout/paid-payout/'+data.id+'/all'"
                                    class="course-id">
                                    <span class="text-blue-600 cursor-pointer">
                                       {{ data.instructor.fullname }}
                                    </span>
                                 </span>
                              </td>
                              <td>
                                 <span class="course-id">
                                    <span class="">
                                       {{ data.date_created | date:'d MMMM y' }}
                                       {{ data.date_created | date:'h:mm:ss a' }}
                                    </span>
                                 </span>
                              </td>
                              <td class="max-w-[170px]">
                                 <span class="">
                                    {{ data.payout_type }}
                                 </span>
                              </td>
                              <td>
                                 {{data.period}}
                              </td>
                              <td>
                                 {{data.no_of_transactions}}
                              </td>
                              <td>
                                 {{data.payment_method}}
                              </td>
                              <td>
                                 {{data.amount | currency:data.currency_symbol}}
                              </td>
                              <td>
                                 <div [ngClass]="getStatus(data.status).bg"
                                    class="flex flex-col px-2.5 py-[5px] rounded-2xl">
                                    <span [ngClass]="getStatus(data.status).text" class="text-sm">{{data.status}}</span>
                                 </div>
                              </td>
                              <td>
                                 <a *ngIf="data.receipt" [href]="data.receipt" target="_blank" class="btn p-3 bg-blue-50 rounded-[4px] gap-2 border-0">
                                    <svg-icon src="@assets/images/icons/document-report.svg" class="text-blue-600">
                                    </svg-icon>
                                 </a>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
   
               </div>
            </div>
         </ng-template>
         <div class="card-body">
            <div class="courses-list relative">
               <ng-container *ngIf="isLoading">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
               </ng-container>
               <ng-container *ngIf="paidPayoutsPagination?.results?.length === 0 && !isLoading; else isPayouts">
                  <span class="text-center text-2xl my-11 block">No Items Founded!</span>
               </ng-container>
               <ng-template #isPayouts>
                  <ng-container *ngTemplateOutlet="paidPayout; context: { items: paidPayoutsPagination?.results }">
                  </ng-container>
               </ng-template>
               <!-- Pagination -->
               <div class="z-30 relative">
                  <app-common-pagination
                     (paginatorData)="paginationAction($event)"
                     (pageSize)="pageSizeAction($event)"
                     [config]="paginationConfig"
                     [type]="'dynamic'">
                  </app-common-pagination>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>
