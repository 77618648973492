import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PaginationConfig, RefundQueryParams, UntilDestroy, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { debounceTime, distinctUntilChanged, firstValueFrom, Subscription, switchMap } from 'rxjs';
import {
  AdminInstructorBalance,
  AdminPayoutInstructorBalancesQuery,
  Pagination,
  PaginationService,
  PayoutType,
  QueryFormConverter,
  QueryStringFilterService,
} from 'thkee-common';
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-instructor-balance',
  templateUrl: './instructor-balance.component.html',
  styleUrls: ['./instructor-balance.component.scss'],
  providers: [QueryStringFilterService.forComponent()],
})
export class InstructorBalanceComponent implements OnInit, AfterViewInit, OnDestroy {
  query?: AdminPayoutInstructorBalancesQuery;
  balancesPagination?: Pagination<AdminInstructorBalance>;

  queryParams: RefundQueryParams = { page: 1, page_size: pageSize };
  isLoading: boolean = false;

  subscriptions = new Subscription();

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [
        {
          key: PayoutType.onDemand,
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand (16)',
            required: true,
          },
        },
        {
          key: PayoutType.monthly,
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly (16)',
            required: true,
          },
        },
      ],
    },
  ];

  private formConverter = new QueryFormConverter<AdminPayoutInstructorBalancesQuery>(['payout_type']);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private queryFilterService: QueryStringFilterService<AdminPayoutInstructorBalancesQuery>,
    private payoutService: PayoutService,
    private activatedRoute: ActivatedRoute,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.queryUpdater();
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Instructor Balances',
        url: '/ecommerce/payout/instructor-balances',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getInstructorBalances();
    });
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  search(instructorName: string) {
    if (instructorName.trim()) {
      this.queryFilterService.patch({ search: instructorName.trim() });
      return;
    }

    this.queryFilterService.remove(['search']);
  }

  paginationConfig: PaginationConfig = {
    pageCountSummary: true,
    pageSize: true,
    firstAndLast: true,
  };

  pageSizeAction(value: number) {
    this.queryParams.page_size = value;
    this.queryParams.page = 1;
    this.getInstructorBalances();
  }
  paginationAction(page_number: number) {
    this.queryParams.page = page_number;
    this.getInstructorBalances();
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // Dropdown methode
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // private setupFilter() {
  //   this.queryFilterService.initialValue.subscribe(query => {
  //     this.query = query;
  //     this.filterForm.patchValue(this.formConverter.toForm(query));
  //   });

  //   this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
  //     this.queryFilterService.patch(this.formConverter.toQuery(value as any));
  //   })
  // }

  private setupFilter() {
    this.queryFilterService.initialValue.pipe(untilDestroyed(this)).subscribe((query) => {
      this.query = query;
      const formValue = this.formConverter.toForm(query);

      // Ensure no duplicate patches
      if (JSON.stringify(this.filterForm.value) !== JSON.stringify(formValue)) {
        this.filterForm.patchValue(formValue, { emitEvent: false });
      }
    });

    this.filterForm.valueChanges
      .pipe(
        debounceTime(400),
        untilDestroyed(this),
        switchMap((value) => {
          const updatedQuery = this.formConverter.toQuery(value as any);
          if (JSON.stringify(updatedQuery) === JSON.stringify(this.query)) {
            return []; // Skip duplicate updates
          }
          this.queryFilterService.patch(updatedQuery);
          return this.queryFilterService.valueChanges;
        }),
        distinctUntilChanged()
      )
      .subscribe(() => this.getInstructorBalances());
  }

  private getInstructorBalances() {
    this.isLoading = true;
    this.subscriptions.add(
      this.payoutService.getInstructorBalances(this.queryParams).subscribe({
        next: (res) => {
          this.balancesPagination = res;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (err) => {
          console.error('API Error:', err);
          this.isLoading = false;
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
