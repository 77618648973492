import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CourseStatusV2, LectureV3, PartialEntity, QuizDetailV3 } from '../../models';
import { CourseStateV2 } from './course.state';

export const selectCourseDetailsV2State = createFeatureSelector<CourseStateV2>('courseStateV2');

// Course details
export const selectCourseFullDetails = createSelector(selectCourseDetailsV2State, (state) => state);
export const selectCourseV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.courseDetails;
});

export const selectCourseDetailV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return {
    ...state?.courseDetails,
    sections: [...state.courseCurriculum.results.sections],
  };
});

// Annotations
export const selectFeedbackV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.annotations.annotations;
});

// Course Learner
export const selectCourseLearnerV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.courseLerner;
});

export const selectLearnerV2 = (learnerId: string) =>
  createSelector(selectCourseDetailsV2State, (state) => {
    let findIndex: number = state.courseLerner.learners.findIndex((learner) => learner.id === learnerId);
    return state.courseLerner.learners[findIndex];
  });

export const selectObjectiveV2 = (learnerId: string) =>
  createSelector(selectCourseDetailsV2State, (state) => {
    let findIndex: number = state.courseLerner.objectives.findIndex((learner) => learner.id === learnerId);
    return state.courseLerner.objectives[findIndex];
  });

export const selectRequirementV2 = (learnerId: string) =>
  createSelector(selectCourseDetailsV2State, (state) => {
    let findIndex: number = state.courseLerner.requirements.findIndex((learner) => learner.id === learnerId);
    return state.courseLerner.requirements[findIndex];
  });

// Course curriculum
export const selectCourseCurriculumV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.courseCurriculum;
});

// Course upload
export const selectUploadsV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.uploads;
});

// Activity handler
export const selectActivityStateV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.activityState;
});

export const selectIsActivityModalOpenV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.isActivityModalOpen;
});

export const selectSelectedActivityIdV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.selectedActivityId;
});

export const selectSelectedSectionIdV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.selectedSectionId;
});

export const selectSelectedSubsectionIdV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.selectedSubsectionId;
});

// Section
export const selectSectionV2 = (sectionId: string) =>
  createSelector(selectCourseCurriculumV2, (state) => {
    const index: number = state.results.sections.findIndex((section) => section.id === sectionId);
    return state.results.sections[index];
  });

// Courses lecture
export const selectSubsectionV2 = createSelector(selectCourseDetailsV2State, (state) => {
  return state.courseCurriculum.results.sections.flatMap((section) => section.subsections);
});

export const selectLectureV2 = (lectureId: string) =>
  createSelector(selectSubsectionV2, (subsections) => {
    const subsection = subsections.find(
      (subsection) => subsection.type === 'lecture' && subsection?.lecture?.id === lectureId
    );

    return subsection?.lecture as PartialEntity<LectureV3>;
  });

export const subsectionSelectorV2 = (id: string, subsectionType: string = 'lecture') =>
  createSelector(selectSubsectionV2, (subsections) => {
    let subsection;
    if (subsectionType === 'lecture') {
      subsection = subsections.find((subsection) => subsection.type === 'lecture' && subsection?.lecture?.id === id);
    }

    if (subsectionType === 'quiz') {
      subsection = subsections.find((subsection) => subsection.type === 'quiz' && subsection?.quiz?.id === id);
    }

    if (subsectionType === 'assignment') {
      subsection = subsections.find(
        (subsection) => subsection.type === 'assignment' && subsection?.assignment?.id === id
      );
    }

    return subsection;
  });

export const selectLectureResourceV2 = (lectureId: string) =>
  createSelector(selectSubsectionV2, (subsections) => {
    const subsection = subsections.find(
      (subsection) => subsection.type === 'lecture' && subsection?.lecture?.id === lectureId
    );
    return subsection?.lecture?.resources;
  });

// Course Quiz
export const selectQuizV2 = (quizId: string) =>
  createSelector(selectSubsectionV2, (subsections) => {
    const subsection = subsections.find((subsection) => subsection.type === 'quiz' && subsection?.quiz?.id === quizId);
    return { ...subsection?.quiz, subsectionId: subsection?.id } as PartialEntity<QuizDetailV3>;
  });

export const selectQuizQuestionDetailsByQuizIdV2 = (quizId: string) =>
  createSelector(selectSubsectionV2, (subsections) => {
    const quiz = subsections.find((subsection) => subsection.type === 'quiz' && subsection?.quiz?.id === quizId)?.quiz;
    if (!quiz) {
      return [];
    }

    const data = quiz?.questions?.map((question) => ({
      id: question.id,
      date_updated: question.date_updated,
      question: question.question,
      lecture: question.lecture,
      source: question.source ?? null,
      quiz: quiz?.id,
      answers: question.answers.map((answer) => ({
        id: answer.id,
        date_updated: answer.date_updated,
        answer: answer.answer,
        is_correct: answer.is_correct,
        expected: answer.expected,
        source: answer.source,
        quiz_question: question.id,
      })),
    }));

    return data as any;
  });

// Assignment
export const selectAssignmentDetailByAssignmentIdV2 = (assignmentId: string) =>
  createSelector(selectSubsectionV2, (subsections) => {
    const assignment = subsections.find(
      (subsection) => subsection.type === 'assignment' && subsection?.assignment?.id === assignmentId
    )?.assignment;

    const subsectionId = subsections.find((subsection) => subsection?.assignment?.id === assignmentId)?.id;

    if (!assignment) {
      return [];
    }

    const data = {
      id: assignment.id,
      date_updated: assignment.date_updated,
      section: assignment?.section,
      source: assignment.source ?? null,
      title: assignment?.title,
      subsectionId: subsectionId,
      questions: assignment?.questions?.map((question) => ({
        id: question.id,
        expected: question.expected,
        question: question.question,
        date_updated: question.date_updated,
        source: question.source,
        assignment: assignment.id,
      })),
    };

    return data as any;
  });

// Course editable
const EDITABLE_COURSE_STATUS_V2 = [CourseStatusV2.DRAFT, CourseStatusV2.REJECTED];
export const selectIsCourseEditableV2 = createSelector(
  selectCourseV2,
  (course) => !!course.status && EDITABLE_COURSE_STATUS_V2.includes(course.status)
);

export const selectIsCourseUneditableV2 = createSelector(selectIsCourseEditableV2, (isEditable) => !isEditable);

// Admin courses history
export const selectCoursesVersionHistory = createSelector(selectCourseDetailsV2State, (state) => {
  return state?.coursesVersionHistory;
});
