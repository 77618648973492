import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  CurrencyCRUDStatus,
  ExchangRateActions,
  ExchangRateCRUDStatus,
  ModalComponent,
  PaginationConfig,
  PricingCurrencyActions,
  PricingCurrencyCreateUpdatePayload,
  PricingCurrencyPayload,
  UntilDestroy,
  selectCurrencyCRUDStatus,
  selectExchangeRateCRUDStatus,
  selectPricingCurrencyList,
} from '@shared';
import * as _ from 'lodash';
import { Observable, firstValueFrom } from 'rxjs';
import {
  CURRENCIES,
  ConstantCurrencyOption,
  ModalService,
  PaginationService,
  Params,
  PricingCurrency,
  PricingCurrencyData,
  ToastService,
} from 'thkee-common';
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-price-currencies',
  templateUrl: './price-currencies.component.html',
  styleUrls: ['./price-currencies.component.scss'],
})
export class PriceCurrenciesComponent implements OnInit {
  @ViewChild('liveRates') liveRates!: ModalComponent;

  title: string = 'Currencies';
   queryParams: Params = { page: 1, page_size: pageSize };

   isLoading: Boolean = true;
  currencyOptions: ConstantCurrencyOption[] = CURRENCIES;

  pricingCurrencyList$: Observable<PricingCurrencyData[]> = this.store.select(selectPricingCurrencyList);
  priceCurrencyData: Partial<PricingCurrency>[] = [];
  status$: Observable<CurrencyCRUDStatus> = this.store.select(selectCurrencyCRUDStatus);
  rateStatus$: Observable<ExchangRateCRUDStatus> = this.store.select(selectExchangeRateCRUDStatus);
  pricingCurrencyList: any = [];
  pricingCurrencyPayload: PricingCurrencyPayload = { detailed: false };

  currencyUpdatePayload!: PricingCurrencyCreateUpdatePayload;

  currency_code: string = '';

  primary: any = {
    is_primary_exchange_rate_currency: 0,
    is_primary_store_currency: 0,
  };

  // Search
  isDropdown: boolean[] = [];
  // Topics form
  currenciesFilterForm = new FormGroup({});
  currenciesFilterFormModel: PricingCurrencyCreateUpdatePayload = {
    rate: '',
    is_primary_exchange_rate_currency: false,
    is_primary_store_currency: false,
    pricing_tier_status: false,
  };
  currenciesFilterFormFields: FormlyFieldConfig[] = [
    {
      key: 'rate',
      type: 'input',
      props: {
        label: $localize`Rate`,
        wrapClass: 'mb-4 input-group',
        labelClass: 'font-bold text-black text-lg',
        placeholder: $localize`Rate`,
      },
    },
    {
      key: 'is_primary_exchange_rate_currency',
      type: 'checkbox',
      props: {
        label: $localize`Is Primary Exchange Rate Currency`,
        wrapClass: 'mb-0 input-group',
        labelClass: 'font-bold text-black text-lg pb-2.5',
      },
    },
    {
      key: 'is_primary_store_currency',
      type: 'checkbox',
      props: {
        label: $localize`Is Primary Store Currency`,
        wrapClass: 'mb-0 input-group',
        labelClass: 'font-bold text-black text-lg pb-2.5',
      },
    },
    {
      key: 'pricing_tier_status',
      type: 'checkbox',
      props: {
        label: $localize`Pricing Tier Status`,
        wrapClass: 'mb-0 input-group',
        labelClass: 'font-bold text-black text-lg pb-2.5',
      },
    },
  ];

  constructor(
    private store: Store,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.queryUpdater();
    this.store.dispatch(PricingCurrencyActions.clearCurrencyCRUDStatus());

    this.pricingCurrencyList$.subscribe((pricingCurrencyList) => {
      this.paginationService.getPager(pricingCurrencyList?.length, this.queryParams.page, this.queryParams.page_size);
      const is_primary_exchange_rate_currency = _.find(pricingCurrencyList, {
        is_primary_exchange_rate_currency: true,
      });
      if (is_primary_exchange_rate_currency) {
        this.primary.is_primary_exchange_rate_currency = is_primary_exchange_rate_currency.id;
      }
      const is_primary_store_currency = _.find(pricingCurrencyList, { is_primary_store_currency: true });
      if (is_primary_store_currency) {
        this.primary.is_primary_store_currency = is_primary_store_currency.id;
      }
      this.isLoading = false;
    });
  }

  private queryUpdater() {
      firstValueFrom(this.activatedRoute.queryParams).then((params) => {
        this.queryParams.page = params['page'] ? +params['page'] : 1;
        this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
        this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
      });
    }

  // pagination 
  paginationConfig: PaginationConfig = {
      pageCountSummary: true,
      pageSize: true,
      firstAndLast: true,
    };
      
    pageSizeAction(value: number) {
      this.isLoading = true;
      this.queryParams.page_size = value;
      this.queryParams.page = 1
      this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
    }
  
    paginationAction(page_number: number) {
      this.isLoading = true;
      this.queryParams.page = page_number;
      this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
    }

  onToggle(isChecked: any, id: string, currency_code: string | number, key: string) {
    const newValue = isChecked.target.checked;
    if (currency_code && key) {
      // let dataIndex = _.findIndex(this.currencyData, { id: Number(id) });
      // this.currencyData[dataIndex][key] = newValue;
      // API Call Patch Here
      // console.log('API Call Patch Here', currency_code, this.currencyOptions[dataIndex]);
      const selectedOption = this.currencyOptions.find((currency) => currency.currency_code === currency_code);
      console.log('API Call Patch Here', newValue, id, currency_code, selectedOption);
    }
  }

  handleApply(currencyData: PricingCurrencyData): void {
    if (currencyData && currencyData.currency_code) {
      const currencyUpdatePayload = {
        id: currencyData.id,
        rate: Number(currencyData.rate).toFixed(6),
      };
      this.store.dispatch(ExchangRateActions.updateCurrencyRate(currencyUpdatePayload));
      this.rateStatus$.subscribe((status) => {
        if (status === ExchangRateCRUDStatus.Success) {
          this.toastService.message({
            message: `${currencyData.currency_code} currency rate updated successfully`,
          });
          setTimeout(() => {
            this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
          }, 1000);
        } else {
          this.toastService.error({
            message: `Something went wrong. Please try again.`,
          });
        }
        this.store.dispatch(PricingCurrencyActions.clearCurrencyCRUDStatus());
      });
    }
  }

  remove(data: PricingCurrencyData) {
    // console.log(data);
    this.store.dispatch(PricingCurrencyActions.removePricingCurrency({ id: data?.id }));

    this.status$.subscribe((status) => {
      console.log('Status: ', status);
      if (status === CurrencyCRUDStatus.Success) {
        this.toastService.message({
          message: `Currency removed successfully`,
        });
      }

      if (status === CurrencyCRUDStatus.Failed) {
        this.toastService.error({
          message: `Something went wrong. Please try again.`,
        });
      }
    });
  }

  deleteConfirmation(data: PricingCurrencyData) {
    this.modalService.confirm({
      title: 'Delete Confirmation',
      message: `Are you sure you want to delete this currency?`,
      onConfirm: () => {
        this.remove(data);
      },
    });
  }

  onChangePrimary(event: any, type: string, id: any) {
    // Disable Primary Changer
    // if (event.target.checked) {
    //   const oldPrimary = this.primary[type];
    //   this.primary[type] = id;
    //   setTimeout(() => {
    //     this.primary[type] = oldPrimary;
    //   }, 0);
    //   this.modalService.confirm({
    //     title: 'Warning',
    //     message: `Are you sure you want to set this primary?`,
    //     onConfirm: () => {
    //       this.primary[type] = id;
    //       // Set False for old
    //       if (oldPrimary) {
    //         this.currencyUpdatePayload = { id: oldPrimary };
    //         this.currencyUpdatePayload[type] = false;
    //         this.store.dispatch(ExchangRateActions.updateCurrencyRate(this.currencyUpdatePayload));
    //       }
    //       setTimeout(() => {
    //         // Set True for new
    //         this.currencyUpdatePayload = { id: id };
    //         this.currencyUpdatePayload[type] = true;
    //         this.store.dispatch(ExchangRateActions.updateCurrencyRate(this.currencyUpdatePayload));
    //       }, 1000);
    //     },
    //   });
    // }
  }

  search(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.pricingCurrencyPayload.search = inputElement.value;
    this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(this.pricingCurrencyPayload));
  }

  // Dropdown
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  handleClickOutside(type: number) {
    this.isDropdown[type] = false;
  }

  clearFilter() {
    this.currenciesFilterForm.reset();
    this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList({ detailed: false }));
  }
  submitFilter() {
    const payload: PricingCurrencyPayload = {
      ...this.currenciesFilterForm.value,
      detailed: false,
    };
    this.store.dispatch(PricingCurrencyActions.loadPricingCurrencyList(payload));
  }
}
