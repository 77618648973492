<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Tax</h3>
    </div>
  </div>
  <!-- Data record -->
  <ng-template #priceTaxConfigList let-items>
    <div class="user-groups mb-4">
      <app-data-table title="Tax" badge="2 Config">
        <ng-container table>
          <table class="w-full text-left">
            <thead class="heading">
              <tr>
                <th scope="col">
                  <div class="col-name">Name</div>
                </th>
                <th scope="col">
                  <div class="col-name">System Name</div>
                </th>
                <th scope="col">
                  <div class="col-name">Is Active Provider?</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Option</div>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (provider of taxProviderLists?.results; track provider; let i = $index) {
              <tr class="cursor-pointer text-left hover:!bg-neutral-100">
                <td (click)="goDetails()" class="!py-6">
                  <span class="course-id py-3">{{ provider.name }}</span>
                </td>
                <td (click)="goDetails()">
                  <span class="course-id">{{ provider.system_name }}</span>
                </td>
                <td>
                  <div class="tier-status flex">
                    <label class="relative inline-flex cursor-pointer items-center">
                      <input (change)="toggle(i, provider)" type="checkbox" [(ngModel)]="provider.is_active" class="peer sr-only" />
                      <div
                        class="peer-checked:bg-teal peer h-6 w-11 rounded-full bg-neutral-100 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-neutral-900 after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white after:peer-checked:bg-white peer-focus:outline-none peer-focus:ring-0 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
                      ></div>
                      <span class="ms-3 text-sm">{{ provider.is_active ? 'Active' : 'Disabled' }}</span>
                    </label>
                    @if(isLoadingStatus[i]){
                      <div class="absolute" [thkLibProcessing]="true" size="sm"></div>
                    }
                  </div>
                </td>
                <td>
                  <button class="btn-amber-soft btn-sm px-2">
                    <svg-icon class="btn-icon" src="@assets/images/icons/adjustments-vertical.svg"></svg-icon>
                    Configure
                  </button>
                </td>
              </tr>
              } @if(isLoading){
              <!-- <tr class="cursor-pointer text-left hover:!bg-neutral-100">
                <td colspan="4">
                  <div
                    class="flex h-14 w-full items-center justify-center bg-white bg-opacity-50"
                    [thkLibProcessing]="true"
                    size="sm"
                  ></div>
                </td>
              </tr> -->
              }
            </tbody>
          </table>
        </ng-container>
      </app-data-table>
      <!-- Pagination -->
      <!-- <div class="relative z-30">
        <app-common-pagination
          (paginatorData)="paginate($event)"
          
          [type]="'dynamic'"></app-common-pagination>
      </div> -->
    </div>
  </ng-template>

<!-- pagination -->
  <div class="card-body">
    <div class="courses-list relative">
       <ng-container *ngIf="isLoading">
          <ng-container *ngTemplateOutlet="loader"></ng-container>
       </ng-container>
       <ng-container *ngIf="taxProviderLists?.results?.length === 0 && !isLoading; else isItems">
          <span class="text-center text-2xl my-11 block">No Items Founded!</span>
       </ng-container>
       <ng-template #isItems>
          <ng-container *ngTemplateOutlet="priceTaxConfigList; context: { items: taxProviderLists?.results }">
          </ng-container>
       </ng-template>
       <div class="z-30 relative">
          <app-common-pagination
             (paginatorData)="pageSizeAction($event)"
             (pageSize)="pageSizeAction($event)"
             [config]="paginationConfig"
             [type]="'dynamic'">
          </app-common-pagination>
       </div>
    </div>
 </div>
</div>


<!-- loader -->
<ng-template #loader>
  <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
     <div class="loader">
        <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
           <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
           <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
           </path>
        </svg>
     </div>
  </div>
</ng-template>
