import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Column,
  CoursesResult,
  FilterKey,
  FilterOptionComponent,
  FilterOptions,
  PaginationConfig,
  RECENT_UPDATE_TABLE_SEARCH,
  RECENTLY_UPDATED_COURSE_COLUMN,
  Status,
  TableSearch,
} from '@shared';
import { CourseService } from '@shared/services';
import { firstValueFrom } from 'rxjs';
import { CategoryV2, PaginationService, Params, selectCategories, UtilsService } from 'thkee-common';
const pageSize: number = 5;

@Component({
  selector: 'app-updated',
  templateUrl: './updated.component.html',
  styleUrls: ['./updated.component.scss'],
})
export class UpdatedComponent implements OnInit {
  status: string = '';
  title: string = 'Recently Updated';
  seeMore: boolean = false;
  courseData!: CoursesResult;
  isLoading: boolean = false;

  columns: Column[] = RECENTLY_UPDATED_COURSE_COLUMN;
  queryParams: Params = { page: 1, page_size: pageSize };

  // Range filter
  salePriceKey: FilterKey = { key1: 'sale_price_min', key2: 'sale_price_max' };
  courseSoldKey: FilterKey = { key1: 'min_course_sold_count', key2: 'max_course_sold_count' };
  courseSectionKey: FilterKey = { key1: 'min_total_section_count', key2: 'max_total_section_count' };
  courseLectureKey: FilterKey = { key1: 'min_total_lecture_count', key2: 'max_total_lecture_count' };

  filterValues: FilterOptions = {
    category_slug: [],
    reviews: [],
  };
  filterOptions: FilterOptions = {
    category_slug: [],
    reviews: [],
  };
  searchType: TableSearch[] = RECENT_UPDATE_TABLE_SEARCH;
  selectedItems: TableSearch = RECENT_UPDATE_TABLE_SEARCH[0];

  constructor(
    private readonly store: Store,
    private router: Router,
    private utilsService: UtilsService,
    private coursesService: CourseService,
    private paginationService: PaginationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.queryUpdater();
    this.store.select(selectCategories).subscribe((cat) => {
      this.setFilterOptions(cat);
    });
  }
  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getData();
    });
  }

  // Common dropdown
  isDropdown: boolean[] = [];
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Checkbox filter
  setFilterOptions(category: CategoryV2[] = []) {
    const newOption = category.map((item: CategoryV2) => ({
      value: item.slug,
      label: item.name,
    }));
    this.filterOptions.category_slug = newOption;
    this.filterOptions.reviews = [
      { value: '5', label: '5 Star' },
      { value: '4', label: '4 Star' },
      { value: '3', label: '3 Star' },
      { value: '2', label: '2 Star' },
      { value: '1', label: '1 Star' },
    ];
  }

  // Filter values
  filterHander(event: { action: string; data: {} }) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.queryParams = {};
      this.clearFilter([]);
    }
  }

  // Reset filter
  clearFilter(filters: FilterOptionComponent[]) {
    if (filters) {
      filters.forEach((filter: FilterOptionComponent) => {
        filter.reset();
      });
    }
  }

  // Get data
  getData(params: Params = {}, reload: boolean = false) {
    this.isLoading = true;
    this.coursesService.getRecentlyUpdatedCourse(this.queryParams, reload).subscribe({
      next: (courses: CoursesResult) => {
        this.isLoading = false;
        this.courseData = courses;
        this.paginationService.getPager(courses.count, this.queryParams.page, this.queryParams.page_size);
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  // Pageination
  // paginat(page_number: number) {
  //   this.queryParams = Object.assign({}, this.queryParams);
  //   this.queryParams.page = page_number;
  //   this.getData(this.queryParams);
  // }
  paginationConfig: PaginationConfig = {
      pageCountSummary: true,
      pageSize: true,
      firstAndLast: true,
    };
  
    pageSizeAction(value: number) {
      this.queryParams = Object.assign({}, this.queryParams);
      this.queryParams.page_size = value;
      this.getData();
    }
  
    paginationAction(page_number: number) {
      this.queryParams = Object.assign({}, this.queryParams);
      this.queryParams.page = page_number;
      this.getData();
    }

  // Color tag
  getStatusColor(status: Status = 'Draft') {
    const colorMatch: Record<Status, string> = {
      Draft: 'blue',
      Published: 'green',
      Unlisted: 'red',
      Pending: 'yellow',
      Ended: 'gray',
      'In Review': 'yellow',
      Rejected: 'red',
    };
    return colorMatch[status];
  }
  isStatus(status: string): status is Status {
    return (
      status === 'Draft' ||
      status === 'Published' ||
      status === 'Unlisted' ||
      status === 'Pending' ||
      status === 'Ended' ||
      status === 'In Review' ||
      status === 'Rejected'
    );
  }
  getStatusTagColor(status: string): string {
    if (this.isStatus(status)) {
      return this.getStatusColor(status);
    }
    return 'green';
  }

  // Filter
  submitFilter() {
    this.isDropdown[1] = false;
    this.getData();
  }

  // Search
  selectItems(item: TableSearch) {
    this.queryParams = { page: 1, page_size: pageSize };
    this.selectedItems = item;
  }
  search(event: any) {
    this.queryParams.search = `${this.selectedItems.value + ':' + event.target.value}`;
    this.getData(this.queryParams);
  }

  // Table
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  get courseCount(): number {
    if (this.courseData?.count) {
      return this.courseData.count;
    }
    return 0;
  }
}
