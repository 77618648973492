import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  FilterOptionComponent,
  FilterOptionsTopics,
  ModalComponent,
  PaginationConfig,
  SubCategory,
  TopicForm,
  TopicsLists,
  TopicsQueryParams,
  TopicsResponse,
  UntilDestroy,
  untilDestroyed,
} from '@shared';
import { SettingsService } from '@shared/services';
import * as _ from 'lodash';
import { combineLatest, firstValueFrom, Subscription, take } from 'rxjs';
import { generateUuid, ModalService, PaginationService, ToastService, UtilsService } from 'thkee-common';
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-course-topics',
  templateUrl: './course-topics.component.html',
  styleUrls: ['./course-topics.component.scss'],
})
export class CourseTopicsComponent implements OnInit, OnDestroy {
  queryParams: TopicsQueryParams = { page: 1, page_size: pageSize };
  filterValues: FilterOptionsTopics = {
    subcategory: [],
    approved: [],
  };
  filterOptions: FilterOptionsTopics = {
    subcategory: [],
    approved: [],
  };

  isDropdown: boolean[] = [];
  topicsLists!: TopicsResponse;
  isLoading: boolean = false;
  totalTopics: number = 0;
  seeMore: boolean = false;

  // Topics form
  topicForm = new FormGroup({});
  topicFormModel: TopicForm = {
    name: '',
    subcategories: [],
    synonyms: [],
    description: '',
  };
  topicFormFields: FormlyFieldConfig[] = [];

  topicOption: { label: string; value: string | number }[] = [];
  categoryOption: { label: string; value: string | number }[] = [];

  private subscriptions = new Subscription();
  private topicActionSubscriptions = new Subscription();
  private subCategorySubscriptions = new Subscription();

  constructor(
    private settingsService: SettingsService,
    private toastService: ToastService,
    private readonly modalService: ModalService,
    private paginationService: PaginationService,
    private utilsService: UtilsService,
    private readonly store: Store,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.queryUpdater();
    combineLatest([this.settingsService.getAllSubCategory(), this.settingsService.getTopicLists(this.queryParams)])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([categories, topics]) => {
        this.topicsLists = topics;
        this.totalTopics = topics.count;
        this.paginationService.getPager(topics.count, this.queryParams.page, this.queryParams.page_size);
        this.isLoading = false;
        this.setFilterOptions(categories);

        this.topicOption = topics.results.map((topic) => ({
          label: _.startCase(topic.name),
          value: topic.id !== undefined ? topic.id : 0,
        }));

        this.categoryOption = categories.map((category) => ({
          label: _.startCase(category.name),
          value: category.id !== undefined ? category.id : 0,
        }));

        this.initTopicForm();
      });
  }

  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getTopics(this.queryParams);
    });
  }

  // Get data
  private getTopics(params: TopicsQueryParams = {}) {
    this.isLoading = true;
    this.subscriptions.add(
      this.settingsService.getTopicLists(this.queryParams).subscribe({
        next: (res: TopicsResponse) => {
          this.topicsLists = res;
          this.totalTopics = res.count;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      })
    );
  }

  // Managed Sub category
  @ViewChild('topicsFormModal') topicsFormModal!: ModalComponent;
  selectedTopic!: TopicsLists;
  topicActionType: string = '';
  isSubmittedTopic: boolean = false;
  topicAction(type: string, data: TopicsLists = {}) {
    console.log('🚀 ~ CourseTopicsComponent ~ topicAction ~ data:', data);
    this.topicForm.reset();
    this.topicForm.markAsUntouched();
    this.topicActionType = type;
    this.selectedTopic = data;
    this.initTopicForm();
    if (type === 'Add') {
      this.topicsFormModal.open();
    } else if (type === 'Update') {
      this.topicFormModel = {
        ...this.topicFormModel,
        ...data,
        subcategories: _.map(data.subcategories, 'id'),
        synonyms: _.map(data.synonyms, 'id'),
      };

      this.topicForm.patchValue(this.topicFormModel);
      this.initTopicForm();
      this.topicsFormModal.open();
    }
    if (type === 'Block') {
      this.modalService.confirm({
        title: $localize`Active Confirmation`,
        message: $localize`Are you sure, You want to block this topic ?`,
        onConfirm: () => {
          this.blockUnblockTopic(true);
        },
      });
    }
    if (type === 'Active') {
      this.modalService.confirm({
        title: $localize`Block Confirmation`,
        message: $localize`Are you sure, You want to active this topic ?`,
        onConfirm: () => {
          this.blockUnblockTopic(false);
        },
      });
    }
    if (type === 'Delete') {
      this.modalService.confirm({
        title: $localize`Delete Confirmation`,
        message: $localize`Are you sure, You want to delete this topic ?`,
        onConfirm: () => {
          this.deleteTopic();
        },
      });
    }
  }

  initTopicForm() {
    this.topicFormFields = [
      {
        key: 'name',
        type: 'input',
        props: {
          label: $localize`Topics Name`,
          wrapClass: 'mb-0 input-group',
          labelClass: 'font-bold text-black text-lg pb-2.5',
          minLength: 2,
          maxLength: 60,
          placeholder: $localize`English`,
          tips: $localize`Topics Name`,
        },
        validation: {
          messages: {
            minLength: $localize`Name can't be lower than 6 characters.`,
          },
        },
      },
      {
        key: 'name_ar',
        type: 'input',
        props: {
          label: $localize`Topics Name Arabic`,
          hideLabel: true,
          labelClass: 'font-bold text-black text-lg pb-2.5',
          minLength: 2,
          maxLength: 60,
          placeholder: $localize`Arabic`,
          tips: $localize`Topics Name Arabic`,
        },
        validation: {
          messages: {
            minLength: $localize`Name can't be lower than 6 characters.`,
          },
        },
      },
      {
        key: 'slug',
        type: 'input',
        props: {
          label: $localize`Topics slug`,
          hideLabel: true,
          labelClass: 'font-bold text-black text-lg pb-2.5',
          minLength: 2,
          maxLength: 60,
          placeholder: $localize`slug`,
          tips: $localize`Topics slug`,
        },
        validation: {
          messages: {
            minLength: $localize`Slug can't be lower than 6 characters.`,
          },
        },
      },
      {
        key: 'subcategories',
        type: 'select',
        defaultValue: [],
        props: {
          label: $localize`Related Subcategories`,
          placeholder: $localize`Choose Related Subcategories`,
          stylish: true,
          multiple: true,
          options: this.categoryOption,
          feedback: 0,
        },
      },
      {
        key: 'synonyms',
        type: 'select',
        defaultValue: [],
        props: {
          label: $localize`Synonym Topics`,
          placeholder: $localize`Choose Synonym Topics`,
          stylish: true,
          multiple: true,
          options: this.topicOption,
          feedback: 0,
        },
      },
      {
        key: 'description',
        type: 'textarea',
        props: {
          label: $localize`Description`,
          wrapClass: 'mb-0 input-group',
          labelClass: 'font-bold text-black text-lg pb-2.5',
          tips: $localize`Reason Description`,
          placeholder: $localize`English`,
          required: false,
          minHeight: '100px',
          maxHeight: '150px',
        },
      },
      {
        key: 'description_ar',
        type: 'textarea',
        props: {
          label: $localize`Description Arabic`,
          hideLabel: true,
          labelClass: 'font-bold text-black text-lg pb-2.5',
          tips: $localize`Description Arabic`,
          placeholder: $localize`Arabic`,
          required: false,
          minHeight: '100px',
          maxHeight: '150px',
        },
      },
    ];
  }

  // Get data
  private deleteTopic() {
    if (this.selectedTopic.id) {
      this.topicActionSubscriptions.add(
        this.settingsService.deleteTopicStatus(this.selectedTopic.id).subscribe({
          next: (res: TopicsLists) => {
            this.listUpdater('Delete', this.topicsLists.results, this.selectedTopic);
            this.totalTopics--;
          },
          error: (error) => {
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    }
  }

  // Get data
  private blockUnblockTopic(status: boolean) {
    if (this.selectedTopic.id) {
      const payload = {
        id: this.selectedTopic.id,
        approved: status,
      };
      this.topicActionSubscriptions.add(
        this.settingsService.updateTopicStatus(payload, this.selectedTopic.id).subscribe({
          next: (res: TopicsLists) => {
            this.listUpdater('Update', this.topicsLists?.results, res);
          },
          error: (error) => {
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    }
  }

  // Create and Update
  topicCreate() {
    this.isSubmittedTopic = true;
    if (this.topicActionType === 'Add') {
      const payload = {
        id: generateUuid(),
        ...this.topicForm.value,
      };
      this.topicActionSubscriptions.add(
        this.settingsService.createTopic(payload).subscribe({
          next: (res: TopicsLists) => {
            this.topicForm.reset();
            this.topicsFormModal.close();
            this.isSubmittedTopic = false;
            this.listUpdater('Add', this.topicsLists?.results, res);
            this.totalTopics++;
          },
          error: (error) => {
            this.isSubmittedTopic = false;
            this.toastService.error({
              message: error.error.errors[0].detail,
            });
          },
        })
      );
    } else {
      if (this.selectedTopic.id) {
        const payload = {
          id: this.selectedTopic.id,
          ...this.topicForm.value,
        };
        this.topicActionSubscriptions.add(
          this.settingsService.updateTopic(payload, this.selectedTopic.id).subscribe({
            next: (res: TopicsLists) => {
              this.topicForm.reset();
              this.topicsFormModal.close();
              this.isSubmittedTopic = false;
              this.listUpdater('Update', this.topicsLists?.results, res);
            },
            error: (error) => {
              this.isSubmittedTopic = false;
              this.toastService.error({
                message: error.error.errors[0].detail,
              });
            },
          })
        );
      }
    }
  }

  // Common UI actions
  // Filter
  setFilterOptions(subcategory: SubCategory[] = []) {
    const newOption = subcategory.map((item: SubCategory) => ({
      value: item.id ? item.id : '',
      label: item.name ? item.name : '',
    }));
    this.filterOptions.subcategory = newOption;
    this.filterOptions.approved = [
      {
        value: 'true',
        label: 'Active',
      },
      {
        value: 'false',
        label: 'Draft',
      },
    ];
  }

  filterHandler(event: { action: string; data: {} }) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.queryParams = {};
      this.clearFilter([]);
    }
  }

  clearFilter(filters: FilterOptionComponent[]) {
    if (filters) {
      filters.forEach((filter: FilterOptionComponent) => {
        filter.reset();
      });
    }
    this.queryParams = { page: 1, page_size: pageSize };
    this.getTopics(this.queryParams);
  }

  submitFilter(filters: FilterOptionComponent[]) {
    this.isDropdown[1] = false;
    this.getTopics(this.queryParams);
  }

  // Search
  search(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.queryParams.search = inputElement.value;
    this.getTopics(this.queryParams);
  }

  // Dropdown
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  handleClickOutside(type: number) {
    this.isDropdown[type] = false;
  }

  // Pagination
  // paginat(page_number: number) {
  //   this.queryParams = Object.assign({}, this.queryParams);
  //   this.queryParams.page = page_number;
  //   this.getTopics(this.queryParams);
  // }
  paginationConfig: PaginationConfig = {
    pageCountSummary: true,
    pageSize: true,
    firstAndLast: true,
  };
  
  pageSizeAction(value: number) {
    this.queryParams.page_size = value;
    this.queryParams.page = 1
    this.getTopics(this.queryParams);
  }

  paginationAction(page_number: number) {
    this.queryParams.page = page_number;
    this.getTopics(this.queryParams);
  }

  // List updater
  listUpdater(actionType: string, list: TopicsLists[], res: TopicsLists) {
    if (actionType === 'Add') {
      list.unshift(res);
    } else if (actionType === 'Update') {
      const index = list.findIndex((d) => d.id === res.id);
      list.splice(index, 1, res);
    } else {
      const index = list.findIndex((d) => d.id === res.id);
      list.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.topicActionSubscriptions.unsubscribe();
    this.subCategorySubscriptions.unsubscribe();
  }
}
