import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';
import { ModalComponent, PaginationConfig, RefundQueryParams, UntilDestroy, untilDestroyed } from '@shared';
import { CombinationSingleInputValue } from '@shared/components/combination-single-input/combination-single-input.component';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { NestedCheckboxDirective } from 'projects/thkee-common/src/lib/directives/nested-checkbox.directive';
import { catchError, firstValueFrom, of, Subscription } from 'rxjs';
import {
  AdminManyPayoutRequestSummary,
  AdminPayoutRequest,
  AdminPayoutRequestsQuery,
  AdminPayoutRequestsSummary,
  Pagination,
  PaginationService,
  PayoutRequestStatus,
  QueryFormConverter,
  QueryStringFilterService,
  ToastService,
} from 'thkee-common';

const FILTER_PAYOUT_STATUS: { value: PayoutRequestStatus; label: string }[] = [
  { value: 'paid', label: 'Paid' },
  { value: 'requested', label: 'Requested' },
  { value: 'ready', label: 'Ready' },
  { value: 'deactivated', label: 'Deactivated' },
  { value: 'failed', label: 'Failed' },
];
const pageSize: number = 10;

@UntilDestroy()
@Component({
  selector: 'app-payout-request-all',
  templateUrl: './payout-request-all.component.html',
  styleUrls: ['./payout-request-all.component.scss'],
  providers: [...QueryStringFilterService.forComponent()],
})
export class PayoutRequestAllComponent implements OnInit, AfterViewInit {
  // subscriptions 
  subscriptions = new Subscription();
  @ViewChild('payoutCheckboxes', { read: NestedCheckboxDirective })
  private payoutCheckboxes!: NestedCheckboxDirective;
  isLoading: boolean = false;

  @ViewChild('formlyFilter', { read: FormlyForm })
  formlyFilter!: FormlyForm;

  requestsPagination?: Pagination<AdminPayoutRequest>;
  
  queryParams: RefundQueryParams = { page: 1, page_size: pageSize };

  requestsSummary?: AdminPayoutRequestsSummary;

  searchOptions = [
    {
      id: 'id',
      title: 'ID',
      placeholder: 'Enter ID',
    },
    {
      id: 'request_id',
      title: 'Request ID',
      placeholder: 'Enter Request ID',
    },
    {
      id: 'name',
      title: 'Instructor Name',
      placeholder: 'Enter Instructor Name',
    },
  ];

  deactivatingMany = false;

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [
        {
          key: 'on_demand',
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand',
            required: true,
          },
        },
        {
          key: 'monthly',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly',
            required: true,
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'status',
      fieldGroup: FILTER_PAYOUT_STATUS.map((data) => {
        return {
          key: data.value,
          type: 'checkbox',
          className: '',
          props: {
            label: data.label,
            required: true,
          },
        };
      }),
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'requested_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'requested_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'no_of_transactions_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            type: 'number',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'no_of_transactions_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            type: 'number',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  showingDropdown: string = '';
  selectedRequestIds: number[] = [];
  approvingRequestId?: number;
  deactivatingRequestId?: number;
  approvingMany = false;

  searchValue: CombinationSingleInputValue | undefined;
  query?: AdminPayoutRequestsQuery;
  disableManyAction = true;
  confirmationSummary?: AdminManyPayoutRequestSummary;
  processingPayout?: AdminPayoutRequest;
  planToApproveIds: number[] | undefined;
  fetchingRequests = false;
  private queryFormConverter = new QueryFormConverter<AdminPayoutRequestsQuery>(['status', 'payout_type']);

  constructor(
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private queryStringFilterService: QueryStringFilterService<AdminPayoutRequestsQuery>,
    private toastService: ToastService,
    private paginationService: PaginationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.queryUpdater();
    this.getRequestsSummary();

    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Payouts',
        url: '/ecommerce/payout/paid-payout/all',
      },
      {
        label: 'Payout Request',
        url: '/ecommerce/payout/payouts-requests/all',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  
  private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.getRequests();
    });
}

  ngAfterViewInit(): void {
    this.initialFilterForm();
  }

  requestConfirmation(modal: ModalComponent, payoutIds?: number[]) {
    this.planToApproveIds = payoutIds;
    this.payoutService
      .getSelectedPayoutRequestsSummary(payoutIds)
      .subscribe((data) => (this.confirmationSummary = data));
    modal.open();
  }

  handleSelectedChange($event: AdminPayoutRequest[]) {
    this.selectedRequestIds = $event.map((d) => d.id);
    this.disableManyAction = $event.some((p) => p.status !== 'requested');
  }

  // paginate($event: PaginationChange) {
  //   this.queryStringFilterService.patch($event);
  // }

  paginationConfig: PaginationConfig = {
    pageCountSummary: true,
    pageSize: true,
    firstAndLast: true,
  };
    
  pageSizeAction(value: number) {
    this.queryParams.page_size = value;
    this.queryParams.page = 1
    this.getRequests();

  }

  paginationAction(page_number: number) {
    this.queryParams.page = page_number;
    this.getRequests();
  }
      

  toggleDropdown(type: string) {
    if (type !== this.showingDropdown) {
      this.showingDropdown = type;
      return;
    }
    this.showingDropdown = '';
  }

  search($event: CombinationSingleInputValue) {
    const { key, value } = $event;
    if (key && value) {
      this.queryStringFilterService.patch({ search: [key, value].join(':') });
      return;
    }

    this.queryStringFilterService.remove(['search']);
  }

  resetFilterRequests() {
    this.filterForm.reset(undefined, { emitEvent: false });
    this.queryStringFilterService.reset();
    this.toggleDropdown('filter');
  }

  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // approveRequest(data: AdminPayoutRequest) {
  //   this.approvingRequestId = data.id;
  //   this.payoutService.approveRequests([data.id]).subscribe(() => {
  //     this.triggerReloadRequests.next(true);
  //     this.toastService.message({
  //       message: 'Approve payout successfully',
  //       type: 'message'
  //     });
  //     this.approvingRequestId = undefined;
  //   });
  // }

  approveRequests(modal: ModalComponent) {
    this.approvingMany = true;
    this.payoutService
      .approveRequests(this.planToApproveIds)
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.approvingMany = false;
        if (ok) {
          this.payoutCheckboxes.uncheck();
          this.queryStringFilterService.refresh();
          this.toastService.message({
            message: 'Approve payouts successfully',
            type: 'message',
          });
          modal.close();
        }
      });
  }

  deactivateRequests(modal: ModalComponent) {
    this.deactivatingMany = true;
    this.payoutService
      .deactivateRequests(this.planToApproveIds)
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.deactivatingMany = false;
        if (ok) {
          this.toastService.message({
            message: 'Deactivate all payouts successfully',
            type: 'message',
          });
          modal.close();
          this.payoutCheckboxes.uncheck();
          this.queryStringFilterService.refresh();
        }
      });
  }

  // deactivateRequest(data: AdminPayoutRequest) {
  //   this.deactivatingRequestId = data.id;
  //   this.payoutService.deactivateRequests([data.id]).subscribe(() => {
  //     this.triggerReloadRequests.next(true);
  //     this.deactivatingRequestId = undefined;
  //     this.toastService.message({
  //       message: 'Deactivated payout successfully',
  //       type: 'message'
  //     });
  //   });
  // }
  /**
   * Because of formly form will be intialized (available) after onInit,
   * then need to call this function at AfterViewInit hook,
   * making sure the formly form will be available at that time
   */
  private initialFilterForm() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.queryStringFilterService.patch(this.queryFormConverter.toQuery(value as any));
    });

    this.queryStringFilterService.initialValue.subscribe((query) => {
      this.query = query;
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
      if (query.search) {
        const [key, value] = query.search.split(':');
        if (key && value) {
          this.searchValue = { key, value };
        }
      }

      // list only the requested payouts by default
      if (!this.query.status) {
        // TODO: This options will be enabled after tested properly
        this.queryStringFilterService.patch({ status: ['requested'] });
      }
    });
  }

  private getRequests() {
    this.isLoading = true
    this.subscriptions.add(
      this.payoutService.getPayoutRequests(this.queryParams).subscribe({
        next: (response) => {
          this.requestsPagination = response;
          if (response) {
            this.paginationService.getPager(response.count, this.queryParams.page, this.queryParams.page_size);
          }
          this.isLoading = false;
          this.fetchingRequests = false;
        },
        error: () => {
          this.requestsPagination = undefined;
          this.isLoading = false;
          this.fetchingRequests = false;
        },
      })
    );
  }
  // private getRequests() {
  //   this.isLoading = true
  //   this.queryStringFilterService.valueChanges
  //     .pipe(
  //       tap(() => (this.fetchingRequests = true)),
  //       debounceTime(400),
  //       untilDestroyed(this)
  //     )
  //     .subscribe((query) => {
  //       this.payoutService
  //         .getPayoutRequests(query)
  //         .pipe(catchError(() => of(undefined)))
  //         .subscribe({
  //           next: (response) => {
  //             this.requestsPagination = response;
  //             if (response) {
  //               this.paginationService.getPager(response.count, this.queryParams.page, this.queryParams.page_size);
  //             }
  //             this.isLoading = false;
  //             this.fetchingRequests = false;
  //           },
  //           error: () => {
  //             this.requestsPagination = undefined;
  //             this.isLoading = false;
  //             this.fetchingRequests = false;
  //           },
  //         });
  //     });
  // }

  private getRequestsSummary() {
    this.payoutService.getPayoutRequestsSummary().subscribe((data) => (this.requestsSummary = data));
  }
}
