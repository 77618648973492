import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PaginationConfig, PricingGeoActions, PricingGeoPayload, PricingGeoService, selectPricingGeoList } from '@shared';
import { firstValueFrom, Observable } from 'rxjs';
import { GeoLocation, ModalService, PaginationService, ToastService } from 'thkee-common';
const pageSize: number = 10;

@Component({
  selector: 'app-price-geolocation',
  templateUrl: './price-geolocation.component.html',
  styleUrls: ['./price-geolocation.component.scss'],
})
export class PriceGeolocationComponent implements OnInit {
  pricingGeoList$: Observable<GeoLocation[]> = this.store.select(selectPricingGeoList);
  priceGeoData: Partial<GeoLocation>[] = [];
  isLoading: Boolean = true;

  pricingGeoList: any = [];
  pricingGeoPayload!: PricingGeoPayload;
  
  queryParams: PricingGeoPayload = { page: 1, page_size: pageSize };
  constructor(
    private store: Store,
    private pricingGeoService: PricingGeoService,
    private readonly modalService: ModalService,
    private router: Router,
    private readonly toastService: ToastService,
    private paginationService: PaginationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pricingGeoPayload = {
      page: 1,
      page_size: 10,
    };
    this.queryUpdater();
    // this.store.dispatch(PricingGeoActions.loadGeoLocation(this.pricingGeoPayload));
    this.pricingGeoList$.subscribe((item) => {
      this.paginationService.getPager(item?.length, this.queryParams.page, this.queryParams.page_size);
      if (typeof item !== 'undefined') {
        this.pricingGeoList = item || [];
      }
      this.isLoading = false
    });
  }

   private queryUpdater() {
    firstValueFrom(this.activatedRoute.queryParams).then((params) => {
      this.queryParams.page = params['page'] ? +params['page'] : 1;
      this.queryParams.page_size = params['page_size'] ? +params['page_size'] : 10;
      this.store.dispatch(PricingGeoActions.loadGeoLocation(this.queryParams));
    });
  }
  

  paginationConfig: PaginationConfig = {
        pageCountSummary: true,
        pageSize: true,
        firstAndLast: true,
      };
    
      pageSizeAction(value: number) {
        this.isLoading = true;
        this.queryParams.page_size = value;
        this.store.dispatch(PricingGeoActions.loadGeoLocation(this.queryParams));
      }
    
      paginationAction(page_number: number) {
        this.isLoading = true;
        this.queryParams.page = page_number;
        this.store.dispatch(PricingGeoActions.loadGeoLocation(this.queryParams));
      }

  deleteConfirmation(data: GeoLocation) {
    this.modalService.confirm({
      title: 'Delete Confirmation',
      message: `Are you sure?`,
      onConfirm: () => {
        if (data.id) {
          this.pricingGeoService.deleteGeoLoc(data.id).subscribe((response) => {
            this.toastService.message({
              message: `Geolocation successfully deleted..`,
            });
            this.store.dispatch(PricingGeoActions.loadGeoLocation(this.pricingGeoPayload));
          });
        }
      },
    });
  }
}
