import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AnnotationsByProjectResultV2,
  AnnotationsV2,
  API_COURSES_ANNOTATION_UPSERT,
  API_COURSES_ANNOTATIONS_BY_PROJECT_GET,
  API_COURSES_ASSIGNMENT_QUESTION_DELETE,
  API_COURSES_ASSIGNMENT_QUESTION_UPSERT,
  API_COURSES_ASSIGNMENT_UPSERT,
  API_COURSES_LECTURE_RESOURCE_UPSERT,
  API_COURSES_LECTURE_UPSERT,
  API_COURSES_QUIZ_ANSWER_DELETE,
  API_COURSES_QUIZ_ANSWER_UPSERT,
  API_COURSES_QUIZ_QUESTION_DELETE,
  API_COURSES_QUIZ_QUESTION_UPSERT,
  API_COURSES_QUIZ_UPSERT,
  API_COURSES_SECTION_DELETE,
  API_COURSES_SECTION_UPSERT,
  API_COURSES_SUBSECTION_DELETE,
  API_COURSES_SUBSECTION_UPSERT,
  API_COURSES_UPDATE,
  API_POST_EDITOR_IMAGE_UPLOAD,
  AssignmentQuestionV3,
  AssignmentV3,
  CourseCurriculumResponseV2,
  CourseDetailsModelV2,
  CourseLearnerModelV2,
  CourseReviewV2,
  CoursesVersionHistory,
  EditorImageInfo,
  LectureResourceV3,
  LectureV3,
  Params,
  PartialEntity,
  QuizAnswerV3,
  QuizQuestionV3,
  QuizV3,
  SectionV3,
  SubsectionV3,
  V2_API_GET_ADMIN_COURSE_CURRICULUM,
  V2_API_GET_ADMIN_COURSE_DETAILS,
  V2_API_GET_ADMIN_COURSE_HISTORY,
  V2_API_GET_ADMIN_COURSE_LEARNERS,
  V2_API_GET_ADMIN_COURSE_TRANSCODED_PROMO_INFO,
  V2_API_GET_INSTRUCTOR_COURSE_CURRICULUM,
  V2_API_GET_INSTRUCTOR_COURSE_DETAILS,
  V2_API_GET_INSTRUCTOR_COURSE_LEARNERS,
  V2_API_GET_INSTRUCTOR_COURSE_VALIDATION,
  V2_API_POST_ADMIN_COURSE_UNLISTED_RESTRICT_DELETE,
} from '../../models';
import { encodeURL } from '../../utils';
import { HttpService } from '../http';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private readonly http: HttpService) {}

  // **Instructor**
  getInstructorCourseDetails(courseId: string, params = { current_course: true }): Observable<CourseDetailsModelV2> {
    return this.http.get<CourseDetailsModelV2>(
      encodeURL(V2_API_GET_INSTRUCTOR_COURSE_DETAILS, params).replace('<COURSE_ID>', courseId)
    );
  }

  updateCourse(courseId: string, data: CourseDetailsModelV2): Observable<CourseDetailsModelV2> {
    return this.http.put(API_COURSES_UPDATE.replace('<COURSE_ID>', courseId), data);
  }

  getInstructorCourseCurriculum(courseId: string, params: Params): Observable<CourseCurriculumResponseV2> {
    return this.http.get<CourseCurriculumResponseV2>(
      encodeURL(V2_API_GET_INSTRUCTOR_COURSE_CURRICULUM, params).replace('<COURSE_ID>', courseId)
    );
  }

  getInstructorCourseLearner(courseId: string): Observable<CourseLearnerModelV2> {
    return this.http.get<CourseLearnerModelV2>(V2_API_GET_INSTRUCTOR_COURSE_LEARNERS.replace('<COURSE_ID>', courseId));
  }

  courseSubmitValidationCheck(courseId: string): Observable<CourseReviewV2> {
    return this.http.get<CourseReviewV2>(V2_API_GET_INSTRUCTOR_COURSE_VALIDATION.replace('<COURSE_ID>', courseId));
  }

  // Section
  upsertSectionV3(section: PartialEntity<SectionV3>): Observable<SectionV3> {
    return this.http.post(API_COURSES_SECTION_UPSERT, section);
  }

  deleteSectionV3(sectionId: string, params = {} as object): Observable<any> {
    return this.http.delete(encodeURL(API_COURSES_SECTION_DELETE, params).replace('<SECTION_ID>', sectionId));
  }

  deleteSubsectionV3(subsectionId: string): Observable<void> {
    return this.http.delete(API_COURSES_SUBSECTION_DELETE.replace('<SUBSECTION_ID>', subsectionId));
  }

  // Lecture
  upsertLectureV3(lecture: PartialEntity<LectureV3>): Observable<LectureV3> {
    delete lecture?.video_info;
    return this.http.post(API_COURSES_LECTURE_UPSERT, lecture);
  }

  upsertSubsectionV3(subsection: PartialEntity<SubsectionV3>): Observable<SubsectionV3> {
    return this.http.post(API_COURSES_SUBSECTION_UPSERT, subsection);
  }

  upsertLectureResourceV3(lectureResource: PartialEntity<LectureResourceV3>): Observable<LectureResourceV3> {
    return this.http.post(
      API_COURSES_LECTURE_RESOURCE_UPSERT.replace('<LECTURE_PK>', lectureResource.lecture ?? ''),
      lectureResource
    );
  }

  // Quiz
  upsertQuizV3(quiz: PartialEntity<QuizV3>): Observable<QuizV3> {
    return this.http.post(API_COURSES_QUIZ_UPSERT, quiz);
  }

  upsertQuizQuestionV3(quizQuestion: PartialEntity<QuizQuestionV3>): Observable<QuizQuestionV3> {
    return this.http.post(API_COURSES_QUIZ_QUESTION_UPSERT, quizQuestion);
  }

  upsertQuizAnswerV3(quizAnswer: PartialEntity<QuizAnswerV3>): Observable<QuizAnswerV3> {
    return this.http.post(API_COURSES_QUIZ_ANSWER_UPSERT, quizAnswer);
  }

  deleteQuizQuestionV3(quizQuestionId: string): Observable<void> {
    return this.http.delete(API_COURSES_QUIZ_QUESTION_DELETE.replace('<ID>', quizQuestionId));
  }

  deleteQuizAnswerV3(quizAnswerId: string): Observable<void> {
    return this.http.delete(API_COURSES_QUIZ_ANSWER_DELETE.replace('<ID>', quizAnswerId));
  }

  // Assignments
  upsertAssignmentV3(assignment: PartialEntity<AssignmentV3>): Observable<AssignmentV3> {
    if (typeof assignment.source === 'object') {
      assignment.source = assignment.source?.id;
    }
    return this.http.post(API_COURSES_ASSIGNMENT_UPSERT, assignment);
  }

  upsertAssignmentQuestionV3(
    assignmentQuestion: PartialEntity<AssignmentQuestionV3>
  ): Observable<AssignmentQuestionV3> {
    assignmentQuestion = JSON.parse(JSON.stringify(assignmentQuestion));
    if (typeof assignmentQuestion.source === 'object') {
      assignmentQuestion.source = assignmentQuestion.source?.id;
    }
    return this.http.post(API_COURSES_ASSIGNMENT_QUESTION_UPSERT, assignmentQuestion);
  }

  deleteAssignmentQuestionV3(assignmentQuestionId: string): Observable<void> {
    return this.http.delete(API_COURSES_ASSIGNMENT_QUESTION_DELETE.replace('<ID>', assignmentQuestionId));
  }

  // Annotations
  getAnnotationsByProjectIdV3(projectId: string): Observable<AnnotationsByProjectResultV2> {
    return this.http.get<AnnotationsByProjectResultV2>(API_COURSES_ANNOTATIONS_BY_PROJECT_GET, {
      project_id: projectId,
    });
  }

  upsertAnnotationV3(annotation: PartialEntity<AnnotationsV2>): Observable<AnnotationsV2> {
    return this.http.post<AnnotationsV2>(API_COURSES_ANNOTATION_UPSERT, annotation);
  }

  // Editor file upload
  uploadImage(file: File): Observable<EditorImageInfo> {
    const formData = new FormData();
    formData.append('image', file);
    return this.http.post<EditorImageInfo>(API_POST_EDITOR_IMAGE_UPLOAD, formData);
  }

  // **Admin**
  // TODO: Admin API service
  getCoursesHistoryByProjectId(projectId: string): Observable<CoursesVersionHistory> {
    return this.http.get<CoursesVersionHistory>(V2_API_GET_ADMIN_COURSE_HISTORY.replace('<PROJECT_ID>', projectId));
  }

  getAdminCourseCurriculum(courseId: string, params: Params): Observable<CourseCurriculumResponseV2> {
    return this.http.get<CourseCurriculumResponseV2>(
      encodeURL(V2_API_GET_ADMIN_COURSE_CURRICULUM, params).replace('<COURSE_ID>', courseId)
    );
  }

  getAdminCourseDetails(courseId: string): Observable<CourseDetailsModelV2> {
    return this.http.get<CourseDetailsModelV2>(V2_API_GET_ADMIN_COURSE_DETAILS.replace('<COURSE_ID>', courseId));
  }

  getAdminCourseIntend(courseId: string): Observable<CourseLearnerModelV2> {
    return this.http.get<CourseLearnerModelV2>(V2_API_GET_ADMIN_COURSE_LEARNERS.replace('<COURSE_ID>', courseId));
  }

  getCourseDetailsTranscodedInfo(courseId: string): Observable<any> {
    return this.http.get<any>(V2_API_GET_ADMIN_COURSE_TRANSCODED_PROMO_INFO.replace('<COURSE_ID>', courseId));
  }

  postAdminCourseAction(payload = {} as object, courseId: string) {
    return this.http.post(V2_API_POST_ADMIN_COURSE_UNLISTED_RESTRICT_DELETE.replace('<COURSE_ID>', courseId), payload);
  }
}
