<div class="price-shares p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>Category Lists</h3>
    </div>
    <button (click)="add()" class="btn-blue btn-sm">
      <svg-icon class="btn-icon" src="@assets/images/icons/plus.svg"></svg-icon>
      <span> Add Category </span>
    </button>
  </div>

  <!-- Data record -->
  <div class="user-groups mb-4">
    <app-data-table title="Total" badge="{{ totalTaxCategory }} Category">
      <ng-container table>
        @if(isLoading){
        <div
          [thkLibProcessing]="true"
          size="md"
          variant="primary"
          class="flex min-h-12 items-center justify-center"
        ></div>
        } @if(!isLoading){
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name">Name</div>
              </th>
              <th scope="col">
                <div class="col-name">Category Code</div>
              </th>
              <th scope="col">
                <div class="col-name">Display Order</div>
              </th>
              <th scope="col" class="w-28">
                <div class="col-name">Option</div>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (category of taxCategoryLists; track category) {
            <tr class="text-left">
              <td>
                <span class="course-id">{{ category.name }}</span>
              </td>
              <td>
                <span class="course-id">{{ category.slug }}</span>
              </td>
              <td>
                <span class="course-id">{{ category.display_order }}</span>
              </td>
              <td>
                <button (click)="update(category)" class="btn-amber-soft btn-sm px-2">
                  <svg-icon class="btn-icon" src="@assets/images/icons/pencil.svg"></svg-icon>
                </button>
              </td>
            </tr>
            }
          </tbody>
        </table>
        }
      </ng-container>
    </app-data-table>
    <!-- Pagination -->
    <!-- <div class="relative z-30">
      <app-common-pagination (paginatorData)="paginate($event)" [type]="'dynamic'"></app-common-pagination>
    </div> -->
  </div>
  <!-- pagination -->
  <div class="card-body">
    <div class="courses-list relative">
       <ng-container *ngIf="isLoading">
          <ng-container *ngTemplateOutlet="loader"></ng-container>
       </ng-container>
       <ng-container *ngIf="taxProviderLists?.results?.length === 0 && !isLoading; else isItems">
          <span class="text-center text-2xl my-11 block">No Items Founded!</span>
       </ng-container>
       <ng-template #isItems>
          <ng-container *ngTemplateOutlet="priceTaxConfigList; context: { items: taxProviderLists?.results }">
          </ng-container>
       </ng-template>
       <!-- Pagination -->
       <div class="z-30 relative">
          <app-common-pagination
             (paginatorData)="paginate($event)"
             (pageSize)="pageSizeAction($event)"
             [config]="paginationConfig"
             [type]="'dynamic'">
          </app-common-pagination>
       </div>
    </div>
 </div>
</div>

<!-- Drawer -->
<app-drawer #drawerSidebar customClass="max-w-[600px] min-w-[560px] !sm:w-full">
  <ng-container header>
    <div class="flex items-center justify-between border-b border-b-neutral-100 bg-neutral-50 px-6 py-5">
      <h3 class="text-xl font-semibold text-black">Add New Category</h3>
      <div class="flex items-center justify-between gap-3">
        <button (click)="drawerSidebar.close()" class="h-6 w-6 rounded-[3px] bg-neutral-100">
          <svg-icon src="@assets/images/icons/x-mark.svg" class="h-3 w-3 text-black"> </svg-icon>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container content>
    <!-- Form -->
    <div class="card mt-4 p-4">
      <div class="m-auto block max-w-xl">
        <form [formGroup]="taxCategory" (submit)="submit()">
          <formly-form [form]="taxCategory" [fields]="taxCategoryFields" [model]="taxCategoryModel"></formly-form>
          <div class="mt-4 flex items-center gap-4">
            <span class="w-40"></span>
            <button
              [disabled]="isSubmitted || taxCategory.invalid"
              [thkLibProcessing]="isSubmitted"
              size="sm"
              variant="white"
              type="submit"
              class="btn-blue btn-sm"
            >
              {{ action === 'create' ? 'Add New Record' : 'Update Record' }}
            </button>
            <button [disabled]="taxCategory.invalid" (click)="reset()" type="button" class="btn-blue btn-sm">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</app-drawer>
