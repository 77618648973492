import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { COURSE_TAB, ModalComponent, TabItem, UntilDestroy, untilDestroyed } from '@shared';
import * as _ from 'lodash';
import { Observable, Subject, combineLatest, distinctUntilChanged, firstValueFrom, map, take } from 'rxjs';
import {
  ActivityState,
  AnnotationDetail,
  AnnotationService,
  AssignmentDetailV2,
  AssignmentQuestionV2,
  ContentTypeApps,
  ContentTypeModels,
  CourseActions,
  CourseActionsV2,
  CourseCurriculumResponseV2,
  CourseCurriculumSectionV2,
  CourseDetailV2,
  CourseService,
  FeedbackState,
  InstructorService,
  LectureDetailV3,
  LoaderService,
  Logger,
  ProjectService,
  QuizDetailV3,
  QuizQuestionDetail2V3,
  QuizQuestionDetailV2,
  QuizQuestionV2,
  RouterStoreService,
  SectionDetailV2,
  SectionV2,
  SubsectionDetailV2,
  SubsectionDetailV3,
  SubsectionType,
  SubsectionTypeV3,
  VideoData,
  generateUuid,
  isDefined,
  selectActivityStateV2,
  selectContentType,
  selectCourseCurriculumV2,
  selectCourseDetail,
  selectCourseV2,
  selectFeedback,
  selectIsActivityModalOpenV2,
  selectIsCourseUneditableV2,
  selectSelectedActivityIdV2,
  selectSelectedSectionIdV2,
  selectUploadsV2,
} from 'thkee-common';

const log = new Logger('CourseCurriculumComponent');
export interface VideoLibraryFilterParams {
  course_ids?: string;
  section_ids?: string;
  sort_by?: string;
  page: number;
  page_size?: number;
}
@UntilDestroy()
@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
})
export class CourseCurriculumComponent implements OnInit {
  @Input() isDefaultView: boolean = true;
  @ViewChild('sectionModal') sectionModal!: ModalComponent;
  @ViewChild('activityModal') activityModal!: ModalComponent;
  @ViewChild('feedbackModal') feedbackModal!: ModalComponent;

  courseState: string = '0';
  readOnly: boolean = true;
  courseId: string = '';

  sectionsData: SectionDetailV2[] = [];
  sectionEdit: boolean[] = [];
  sectionCollapse: boolean[] = [];
  subSectionCollapse: boolean[] = [];
  // sectionData: Record<string, SubsectionDetailV2[]> = {};
  quizCollapse: boolean[] = [];

  haveFeedbackHistory: boolean = false;
  annotation$!: Observable<AnnotationDetail> | undefined;
  courseDetail$!: Observable<CourseDetailV2>;
  feedback$!: Observable<FeedbackState>;

  // activityState$ = this.store.select(selectActivityState);
  private courseId$ = this.routerStore.getParam('courseId');
  // private selectedSectionId$ = this.store.select(selectSelectedSectionId);
  // private selectedActivityId$ = this.store.select(selectSelectedActivityId);
  tabItems: TabItem[] = COURSE_TAB;

  //Hydrate APIs alternative
  sectionsData2!: CourseCurriculumResponseV2;
  sectionsDataCopy: CourseCurriculumSectionV2[] = [];
  courseCurriculumV2$ = this.store.select(selectCourseCurriculumV2);
  isLoading$ = this.loaderService.response('loading-admin-courses-curriculumV2');
  isCourseUneditable$ = this.store.select(selectIsCourseUneditableV2);
  uploads$ = this.store.select(selectUploadsV2);
  activityState$ = this.store.select(selectActivityStateV2);
  private selectedActivityId$ = this.store.select(selectSelectedActivityIdV2);
  private selectedSectionId$ = this.store.select(selectSelectedSectionIdV2);
  course$ = this.store.select(selectCourseV2);
  sectionData: Record<string, SubsectionDetailV3[]> = {};
  // feedback$ = this.store.select(selectFeedbackV2);
  curriculumQueryParams: VideoLibraryFilterParams = { page: 1, page_size: 10 };
  totalCourseCurriculum = 0;
  isActiveWindowsScroll: boolean = false;

  // Subsection actions
  sources$ = new Subject<VideoData>();
  isPlay: { [key: string]: boolean } = {};
  isLoadingPlay: { [key: string]: boolean } = {};

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private projectService: ProjectService,
    private annotationService: AnnotationService,
    private loaderService: LoaderService,
    private instructorService: InstructorService,
    private cdr: ChangeDetectorRef,
    private coursesService: CourseService
  ) {}

  ngOnInit(): void {
    this.loadCoursesCurriculum();
    this.courseDetail$ = this.store.select(selectCourseDetail);

    this.courseDetail$.pipe(untilDestroyed(this)).subscribe((courseDetail) => {
      this.sectionsData = _.sortBy(courseDetail.sections, (section) => section.position);
      // this.sectionData = _.groupBy(
      //   courseDetail.sections?.flatMap((section) => section.subsections),
      //   (subsection) => subsection.section!
      // );
      // log.debug('courseDetail', courseDetail);
      // log.debug('sectionsData', this.sectionsData);
      // log.debug('sectionData', this.sectionData);
    });

    this.store
      .select(selectIsActivityModalOpenV2)
      .pipe(untilDestroyed(this))
      .subscribe((isActivityModalOpen) => {
        if (isActivityModalOpen) {
          this.activityModal?.open();
        } else {
          this.activityModal?.close();
        }
      });

    this.courseId$.pipe(untilDestroyed(this)).subscribe((courseId) => {
      this.courseId = courseId;
    });

    this.feedback$ = this.store.select(selectFeedback);

    // handle feedback modal open/close
    this.feedback$
      .pipe(
        map((feedback) => feedback.isFeedbackModalOpen),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((isFeedbackModalOpen) => {
        if (this.feedbackModal) {
          if (isFeedbackModalOpen) {
            this.feedbackModal.open();
          } else {
            this.feedbackModal.close();
          }
        }
      });
  }

  loadCoursesCurriculum() {
    this.courseCurriculumV2$.pipe(untilDestroyed(this)).subscribe((courseDetail: CourseCurriculumResponseV2) => {
      this.sectionsData2 = _.cloneDeep(courseDetail);
      this.sectionsDataCopy = this.sectionsData2.results.sections;
      this.totalCourseCurriculum = courseDetail.count;
    });
  }

  initFields(disabled: boolean = this.readOnly) {}

  actionEvent(event: any) {
    this.initFields(true);

    if (event === 'accept') {
      firstValueFrom(this.courseDetail$).then((courseDetail) => {
        this.projectService.toStatePublished(courseDetail.project.id);
      });
    } else if (event === 'reject') {
      firstValueFrom(this.courseDetail$).then((courseDetail) => {
        this.projectService.toStateRejected(courseDetail.project.id);
      });
    } else if (event === 'edit') {
      this.courseState = '1';
      this.readOnly = false;
    } else if (event === 'cancel') {
      this.courseState = '0';
      this.readOnly = true;
    }
    console.log('🚀  ~ actionEvent ~ this.readOnly:', this.readOnly);
  }

  activitySize = {
    type_lecture: 'max-sm:w-[90%] w-[576px]',
    type_quiz: 'max-sm:w-[90%] w-[576px]',
    type_assignment: 'max-sm:w-[90%] w-[576px]',
    lecture_video: 'max-sm:w-[90%] w-[576px]',
    lecture_article: 'w-11/12 sm:w-10/12 sm:max-w-6xl',
    video_library: 'sm:w-full sm:max-w-3xl',
  };

  async sectionHandler(action: 'created' | 'cancel', data: any) {
    log.debug('sectionHandler Emit -- ', action, data);
    // const model: { title: string } = _.cloneDeep(data);
    // if (action === 'created') {
    //   await firstValueFrom(this.courseId$).then((courseId) => {
    //     this.store.dispatch(
    //       CourseActions.upsertSection({ section: { ...model, course: courseId, id: generateUuid() } })
    //     );
    //   });
    // }

    const model: { title: string } = _.cloneDeep(data);
    if (action === 'created') {
      this.totalCourseCurriculum += 1;
      await firstValueFrom(this.courseId$).then((courseId) => {
        this.store.dispatch(
          CourseActionsV2.createSectionV2({
            section: { ...model, course: courseId, id: generateUuid(), position: this.totalCourseCurriculum },
          })
        );
      });
    }
    this.sectionModal.close();
  }

  createActivity(sectionId: string, type: string) {
    // this.store.dispatch(CourseActions.createActivity({ sectionId }));
    this.store.dispatch(CourseActionsV2.createActivityV2({ sectionId }));
  }

  // editActivity(sectionId: string, subsectionId: string, activityId: string, activityState: ActivityState) {
  //   this.store.dispatch(CourseActions.editActivity({ sectionId, subsectionId, activityId, activityState }));
  // }
  editActivity(
    sectionId: string,
    subsectionId: string,
    activityId: string,
    activityState: ActivityState,
    subsection: any
  ) {
    this.store.dispatch(CourseActionsV2.editActivityV2({ sectionId, subsectionId, activityId, activityState }));
    if (subsection.type === 'lecture' && subsection.lecture.type === 'article') {
      this.store.dispatch(CourseActionsV2.reloadModalV2({ activityState: 'type_lecture' }));
      setTimeout(() => {
        this.store.dispatch(CourseActionsV2.reloadModalV2({ activityState: 'lecture_article' }));
      });
    }
  }

  // onActivityModalNext(activityState: string) {
  //   console.log('activityState--');
  //   this.store.dispatch(CourseActions.modalNext({ activityState: activityState as ActivityState }));
  // }
  onActivityModalNext(activityState: string) {
    this.store.dispatch(CourseActionsV2.modalNextV2({ activityState: activityState as ActivityState }));
  }

  // lectureHandler(action: 'created' | 'saved' | 'cancel', data: any) {
  //   log.debug('lectureHandler Emit -- ', action, data);
  //   // clone data to prevent sending readonly data from store due to formly update to model itself
  //   const lecture: LectureDetailV2 = _.cloneDeep(data);
  //   if (action === 'created') {
  //     const lectureId = generateUuid();
  //     firstValueFrom(this.selectedSectionId$).then((sectionId) => {
  //       this.store.dispatch(
  //         CourseActions.createLecture({
  //           subsection: { id: generateUuid(), section: sectionId, lecture: lectureId, type: SubsectionType.LECTURE },
  //           lecture: {
  //             ...lecture,
  //             id: lectureId,
  //             section: sectionId,
  //           },
  //         })
  //       );
  //     });
  //   } else if (action === 'saved') {
  //     this.store.dispatch(
  //       CourseActions.upsertLecture({
  //         lecture: {
  //           ...lecture,
  //         },
  //         lectureResources: (lecture.resources ?? []).filter(isDefined).map((resource) => ({
  //           ...resource,
  //           id: resource.id ?? generateUuid(),
  //           lecture: lecture.id,
  //         })),
  //       })
  //     );
  //     this.closeActivity();
  //   } else if (action === 'cancel') {
  //     this.closeActivity();
  //   }
  // }

  lectureHandler(action: 'created' | 'saved' | 'cancel', data: any) {
    // clone data to prevent sending readonly data from store due to formly update to model itself
    const lecture: LectureDetailV3 = _.cloneDeep(data);
    if (action === 'created') {
      const lectureId = generateUuid();
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        const currentSection = this.sectionsDataCopy.filter((sec) => sec.id === sectionId);
        this.store.dispatch(
          CourseActionsV2.createLectureV2({
            subsection: {
              id: generateUuid(),
              section: sectionId,
              lecture: lectureId,
              type: SubsectionTypeV3.LECTURE,
              position: currentSection[0].subsections.length + 1,
            },
            lecture: {
              ...lecture,
              id: lectureId,
              section: sectionId,
            },
          })
        );
      });
      if (lecture.type === 'video') {
        this.store.dispatch(CourseActionsV2.reloadModalV2({ activityState: 'type_lecture' }));
        setTimeout(() => {
          this.store.dispatch(CourseActionsV2.reloadModalV2({ activityState: 'lecture_video' }));
        });
      }
    } else if (action === 'saved') {
      this.store.dispatch(
        CourseActionsV2.upsertLectureV2({
          lecture: {
            ...lecture,
          },
          lectureResources: (lecture.resources ?? []).filter(isDefined).map((resource) => ({
            ...resource,
            id: resource.id ?? generateUuid(),
            lecture: lecture.id,
          })),
        })
      );
      this.closeActivity();
    } else if (action === 'cancel') {
      this.closeActivity();
    }
  }

  // quizHandler(action: 'quiz_created' | 'quiz_save' | 'qa_created' | 'qa_saved' | 'cancel' | 'qa_deleted', data: any) {
  //   // clone data to prevent sending readonly data from store due to formly update to model itself
  //   data = _.cloneDeep(data);
  //   log.debug('quizHandler Emit -- ', action, data);
  //   if (action === 'quiz_created') {
  //     const quizDetail: QuizDetailV2 = data;
  //     const quizId = generateUuid();
  //     firstValueFrom(this.selectedSectionId$).then((sectionId) => {
  //       this.store.dispatch(
  //         CourseActions.createQuiz({
  //           subsection: { id: generateUuid(), section: sectionId, quiz: quizId, type: SubsectionType.QUIZ },
  //           quiz: {
  //             id: quizId,
  //             section: sectionId,
  //             title: quizDetail.title,
  //           },
  //         })
  //       );
  //     });
  //   } else if (action === 'quiz_save') {
  //     const quizDetail: QuizDetailV2 = data;
  //     combineLatest([this.selectedSectionId$, this.selectedActivityId$])
  //       .pipe(take(1))
  //       .subscribe(([sectionId, quizId]) => {
  //         this.store.dispatch(
  //           CourseActions.upsertQuiz({
  //             quiz: {
  //               id: quizId,
  //               section: sectionId,
  //               title: quizDetail.title,
  //             },
  //           })
  //         );
  //       });
  //     this.closeActivity();
  //   } else if (action === 'qa_created') {
  //     const questionDetail: QuizQuestionDetailV2 = data;
  //     const quizQuestionId = questionDetail.id ?? generateUuid();
  //     firstValueFrom(this.selectedActivityId$).then((quizId) => {
  //       this.store.dispatch(
  //         CourseActions.upsertQuizQuestion({
  //           quizQuestion: { id: quizQuestionId, quiz: quizId, question: questionDetail.question },
  //           quizAnswers: questionDetail.answers.map((answer) => ({
  //             ...answer,
  //             id: answer.id ?? generateUuid(),
  //             quiz_question: quizQuestionId,
  //           })),
  //         })
  //       );
  //     });
  //   } else if (action === 'qa_saved') {
  //     const questionDetail: QuizQuestionDetailV2 = data;
  //     this.store.dispatch(
  //       CourseActions.upsertQuizQuestion({
  //         quizQuestion: questionDetail,
  //         quizAnswers: questionDetail.answers.map((answer) => ({
  //           ...answer,
  //           id: answer.id ?? generateUuid(),
  //           quiz_question: questionDetail.id,
  //         })),
  //       })
  //     );
  //   } else if (action === 'qa_deleted') {
  //     const questionId: string = data;
  //     this.store.dispatch(
  //       CourseActions.deleteQuizQuestion({
  //         quizQuestionId: questionId,
  //       })
  //     );
  //   } else if (action === 'cancel') {
  //     this.closeActivity();
  //   }
  // }

  quizHandler(action: 'quiz_created' | 'quiz_save' | 'qa_created' | 'qa_saved' | 'cancel' | 'qa_deleted', data: any) {
    data = _.cloneDeep(data);

    if (action === 'quiz_created') {
      const quizDetail: QuizDetailV3 = data;
      const quizId = generateUuid();
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        const currentSection = this.sectionsDataCopy.filter((sec) => sec.id === sectionId);
        this.store.dispatch(
          CourseActionsV2.createQuizV2({
            subsection: {
              id: generateUuid(),
              section: sectionId,
              quiz: quizId,
              type: SubsectionTypeV3.QUIZ,
              position: currentSection[0].subsections.length + 1,
            },
            quiz: {
              id: quizId,
              section: sectionId,
              title: quizDetail.title,
            },
          })
        );
      });
    } else if (action === 'quiz_save') {
      const quizDetail: QuizDetailV3 = data;
      combineLatest([this.selectedSectionId$, this.selectedActivityId$])
        .pipe(take(1))
        .subscribe(([sectionId, quizId]) => {
          this.store.dispatch(
            CourseActionsV2.upsertQuizV2({
              quiz: {
                id: quizId,
                section: sectionId,
                title: quizDetail.title,
              },
            })
          );
        });
      this.closeActivity();
    } else if (action === 'qa_created') {
      const questionDetail: QuizQuestionDetail2V3 = data;
      const quizQuestionId = questionDetail.id ?? generateUuid();
      combineLatest([this.selectedSectionId$, this.selectedActivityId$])
        .pipe(take(1))
        .subscribe(([sectionId, quizId]) => {
          this.store.dispatch(
            CourseActionsV2.createQuizQuestionV2({
              quizQuestion: { id: quizQuestionId, quiz: quizId, question: questionDetail.question },
              quizAnswers: questionDetail.answers.map((answer) => ({
                ...answer,
                id: answer.id ?? generateUuid(),
                quiz_question: quizQuestionId,
              })),
              sectionId: sectionId,
              subsectionId: questionDetail.subsectionId,
            })
          );
        });
    } else if (action === 'qa_saved') {
      const questionDetail: QuizQuestionDetail2V3 = data;
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        this.store.dispatch(
          CourseActionsV2.upsertQuizQuestionV2({
            quizQuestion: questionDetail,
            quizAnswers: questionDetail.answers.map((answer) => ({
              ...answer,
              id: answer.id ?? generateUuid(),
              quiz_question: questionDetail.id,
            })),
            sectionId: sectionId,
            subsectionId: questionDetail.subsectionId,
          })
        );
      });
    } else if (action === 'qa_deleted') {
      const questionDetail: { questionId: string; subsectionId: string } = data;
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        this.store.dispatch(
          CourseActionsV2.deleteQuizQuestionV2({
            quizQuestionId: questionDetail.questionId,
            sectionId: sectionId,
            subsectionId: questionDetail.subsectionId,
          })
        );
      });
    } else if (action === 'cancel') {
      this.closeActivity();
    }
  }

  // assignmentHandler(action: 'created' | 'save' | 'cancel', data: any) {
  //   log.debug('assignmentHandler Emit -- ', action, data);
  //   data = _.cloneDeep(data);
  //   if (action === 'created') {
  //     const assignmetnDetail: AssignmentDetailV2 = data;
  //     const assignmentId = generateUuid();
  //     firstValueFrom(this.selectedSectionId$).then((sectionId) => {
  //       this.store.dispatch(
  //         CourseActions.createAssignment({
  //           subsection: {
  //             id: generateUuid(),
  //             section: sectionId,
  //             assignment: assignmentId,
  //             type: SubsectionType.ASSIGNMENT,
  //           },
  //           assignment: {
  //             id: assignmentId,
  //             section: sectionId,
  //             title: assignmetnDetail.title,
  //           },
  //         })
  //       );
  //     });
  //   } else if (action == 'save') {
  //     const assignmentDetail: AssignmentDetailV2 = data;
  //     combineLatest([this.selectedSectionId$, this.selectedActivityId$])
  //       .pipe(take(1))
  //       .subscribe(([sectionId, assignmentId]) => {
  //         this.store.dispatch(
  //           CourseActions.upsertAssignment({
  //             assignment: { id: assignmentId, section: sectionId, title: assignmentDetail.title },
  //             assignmentQuestions: assignmentDetail.questions.map((question) => ({
  //               ...question,
  //               id: question.id ?? generateUuid(),
  //               assignment: assignmentId,
  //             })),
  //           })
  //         );
  //       });
  //     this.closeActivity();
  //   } else if (action == 'cancel') {
  //     this.closeActivity();
  //   }
  // }

  assignmentHandler(action: 'created' | 'save' | 'cancel', data: any) {
    data = _.cloneDeep(data);
    if (action === 'created') {
      const assignmentDetail: AssignmentDetailV2 = data;
      const assignmentId = generateUuid();
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        const currentSection = this.sectionsDataCopy.filter((sec) => sec.id === sectionId);
        this.store.dispatch(
          CourseActionsV2.createAssignmentV2({
            subsection: {
              id: generateUuid(),
              section: sectionId,
              assignment: assignmentId,
              type: SubsectionTypeV3.ASSIGNMENT,
              position: currentSection[0].subsections.length + 1,
            },
            assignment: {
              id: assignmentId,
              section: sectionId,
              title: assignmentDetail.title,
            },
          })
        );
      });
    } else if (action == 'save') {
      const assignmentDetail: AssignmentDetailV2 = data;
      combineLatest([this.selectedSectionId$, this.selectedActivityId$])
        .pipe(take(1))
        .subscribe(([sectionId, assignmentId]) => {
          this.store.dispatch(
            CourseActionsV2.upsertAssignmentV2({
              assignment: { id: assignmentId, section: sectionId, title: assignmentDetail.title },
              assignmentQuestions: assignmentDetail.questions.map((question) => ({
                ...question,
                id: question.id ?? generateUuid(),
                assignment: assignmentId,
              })),
            })
          );
        });
      this.closeActivity();
    } else if (action == 'cancel') {
      this.closeActivity();
    }
  }

  editSection(sectionId: string, title: string) {
    // this.store.dispatch(CourseActions.upsertSection({ section: { id: sectionId, title } }));
    this.store.dispatch(CourseActionsV2.upsertSectionV2({ section: { id: sectionId, title } }));
  }

  deleteSection(sectionId: string) {
    // this.store.dispatch(CourseActions.deleteSection({ sectionId }));

    const params = { course: this.courseId };
    // this.store.dispatch(CourseActions.deleteSection({ sectionId, params }));
    this.store.dispatch(CourseActionsV2.deleteSectionV2({ sectionId, params }));
  }

  deleteSubsection(subsectionId: string, sectionId: string) {
    // this.store.dispatch(CourseActions.deleteSubsection({ subsectionId }));
    this.store.dispatch(CourseActionsV2.deleteSubsectionV2({ subsectionId, sectionId }));
  }

  closeActivity() {
    // this.store.dispatch(CourseActions.closeActivity());
    this.store.dispatch(CourseActionsV2.closeActivityV2());
  }

  onFeedbackOpenSection(section: SectionV2) {
    log.debug('onFeedbackOpenSection', section);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'title',
        fieldValue: section.title,
        fieldModel: ContentTypeModels.SECTION,
        fieldId: section.id,
      })
    );
  }

  private subsectionContentTypeMap = {
    [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
    [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
    [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
  };
  onFeedbackOpenActivity(subsection: SubsectionDetailV2) {
    log.debug('onFeedbackOpenActivity', subsection);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'title',
        fieldValue: subsection[subsection.type!]?.title ?? '',
        fieldModel: this.subsectionContentTypeMap[subsection.type!],
        fieldId: subsection[subsection.type!]?.id ?? '',
      })
    );
  }

  onFeedbackOpenQuizQuestion(quizQuestion: QuizQuestionDetailV2) {
    log.debug('onFeedbackOpenQuizQuestion', quizQuestion);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'question',
        fieldValue: quizQuestion.question,
        fieldModel: ContentTypeModels.QUIZ_QUESTION,
        fieldId: quizQuestion.id,
      })
    );
  }

  onFeedbackOpenAssignmentQuestion(assignmentQuestion: AssignmentQuestionV2) {
    log.debug('onFeedbackOpenAssignmentQuestion', assignmentQuestion);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'question',
        fieldValue: assignmentQuestion.question,
        fieldModel: ContentTypeModels.ASSIGNMENT_QUESTION,
        fieldId: assignmentQuestion.id,
      })
    );
  }

  async onFeedbackSubmit(annotation: Partial<AnnotationDetail>) {
    log.debug('onFeedbackSubmit');
    const feedback = await firstValueFrom(this.feedback$);
    const contentType = await firstValueFrom(
      this.store.select(selectContentType(ContentTypeApps.COURSES, feedback.fieldModel))
    );
    this.store.dispatch(
      CourseActions.upsertAnnotation({
        annotation: {
          ...annotation,
          id: annotation.id ?? generateUuid(),
          content_type: contentType?.id ?? 0,
          course: this.courseId,
          feedback: annotation.feedback ?? '',
          field: feedback.fieldKey,
          object_id: feedback.fieldId,
          required_action: annotation.required_action ?? false,
          model: feedback.fieldModel,
        },
      })
    );
  }

  onFeedbackCancel() {
    log.debug('onFeedbackCancel');

    this.store.dispatch(CourseActions.closeFeedbackModal());
  }

  getAnnotationCountSection(section: SectionV2): Observable<number | undefined> {
    return this.annotationService.getAnnotationCountV2('title', ContentTypeModels.SECTION, section.id);
  }

  getAnnotationCountActivity(subsection: SubsectionDetailV2): Observable<number | undefined> {
    const typeMap = {
      [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
      [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
      [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
    };

    const contentType = typeMap[subsection.type ?? ('' as keyof typeof typeMap)];
    const content = subsection[subsection.type as keyof SubsectionDetailV2];

    const contentId = typeof content === 'object' && content !== null ? content.id ?? '' : '';

    return this.annotationService.getAnnotationCountV2(
      'title',
      contentType,
      contentId
      // typeMap[subsection.type ?? ''],
      // subsection[subsection.type ?? ''].id ?? ''
    );
  }

  getAnnotationCountAssignmentQuestion(assignmentQuestion: AssignmentQuestionV2): Observable<number | undefined> {
    return this.annotationService.getAnnotationCountV2(
      'question',
      ContentTypeModels.ASSIGNMENT_QUESTION,
      assignmentQuestion.id
    );
  }

  getAnnotationCountQuizQuestion(quizQuestion: QuizQuestionV2): Observable<number | undefined> {
    return this.annotationService.getAnnotationCountV2('question', ContentTypeModels.QUIZ_QUESTION, quizQuestion.id);
  }

  getFeedbackActivityDate(subsection: SubsectionDetailV2): Observable<{ by: string; date: string } | null> {
    const typeMap = {
      [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
      [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
      [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
    };

    const contentType = typeMap[subsection.type ?? ('' as keyof typeof typeMap)];
    const content = subsection[subsection.type as keyof SubsectionDetailV2];
    const contentId = typeof content === 'object' && content !== null ? content.id ?? '' : '';

    return this.annotationService.getAnnotationDate('title', contentType, contentId).pipe(
      map((date) => {
        if (!date) {
          return null;
        }
        const feedbackDate = new Date(date);
        const currentDate = new Date();
        const feedbackDateWithBuffer = new Date(feedbackDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        if (feedbackDateWithBuffer < currentDate) {
          return null;
        }
        return {
          by: 'Instructor',
          date: feedbackDate.toISOString(),
        };
      })
    );
  }

  // Subsection actions
  togglePlay(subsection: SubsectionDetailV2) {
    this.isPlay = Object.keys(this.isPlay).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as { [key: string]: boolean });
    this.isLoadingPlay[subsection.id] = true;
    this.isPlay[subsection.id] = !this.isPlay[subsection.id];

    if (this.isPlay[subsection.id]) {
      this.instructorService.getLectureTranscodedInfo(subsection.lecture?.id || '').subscribe({
        next: (res) => {
          this.sources$.next(res);
          this.isLoadingPlay[subsection.id] = false;
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  // Load more
  @ViewChild('curriculumScrollContainer') private curriculumScrollContainer?: ElementRef;
  isLoadingCurriculum: boolean = false;
  showLoadMore = false;
  loadMoreCurriculum() {
    if (this.totalCourseCurriculum > this.sectionsDataCopy.length) {
      this.isLoadingCurriculum = true;
      firstValueFrom(this.store.select(selectCourseCurriculumV2)).then((sections) => {
        this.curriculumQueryParams.page += 1;
        this.coursesService.getAdminCourseCurriculum(this.courseId, this.curriculumQueryParams).subscribe({
          next: (data) => {
            this.isLoadingCurriculum = false;
            this.store.dispatch(CourseActionsV2.autoLoadCourseCurriculumSuccess(data));
            setTimeout(() => {
              this.scrollToBottomCurriculum();
            }, 300);
          },
          error: (error) => {
            this.isLoadingCurriculum = false;
            this.store.dispatch(CourseActionsV2.autoLoadCourseCurriculumFail({ error }));
          },
        });
      });
    }
  }

  scrollToBottomCurriculum(): void {
    try {
      this.curriculumScrollContainer?.nativeElement.scrollTo({
        top: this.curriculumScrollContainer?.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    } catch (err) {
      console.error('Scrolling error:', err);
    }
  }

  onScrollDiv(event: Event): void {
    const target = event.target as HTMLElement;
    const scrollPosition = target.scrollTop + target.clientHeight;
    const scrollHeight = target.scrollHeight;
    this.showLoadMore = scrollPosition >= scrollHeight - 100;
  }
}
